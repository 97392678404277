import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet
const GetAllInCarSheetLessonThunk = createAsyncThunk(
    "GetAllInCarSheetLesson/GetAllInCarSheetLessonThunk",
    async ({ studentId }, { rejectWithValue }) => {
        try {
        const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.inCarSheet.GetAllInCarSheetLesson}?studentId=${studentId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const GetAllInCarSheetLessonsSlice = createSlice({
    name: "GetAllInCarSheetLessons",
    initialState: {
        GetAllInCarSheetLessonData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetAllInCarSheetLessonData: (state, action) => {
        state.GetAllInCarSheetLessonData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetAllInCarSheetLessonThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [GetAllInCarSheetLessonThunk.fulfilled]: (state, action) => {
        state.GetAllInCarSheetLessonData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [GetAllInCarSheetLessonThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        state.GetAllInCarSheetLessonData = [];
        },
    },
});

export default GetAllInCarSheetLessonsSlice.reducer;

export const { ResetGetAllInCarSheetLessonData } = GetAllInCarSheetLessonsSlice.actions;

export { GetAllInCarSheetLessonThunk };
