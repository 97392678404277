import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardText, Spinner } from 'reactstrap';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from '../../../components/Component';
import Head from '../../../layout/head/Head';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { GetAllInCarSheetLessonThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetLessonSlice';
import { useSelector } from 'react-redux';

const ShowAllInCarLesson = ({ setRoadTestNotes, setManeuverNotes, setPage, setModal, setSelectedLessonId, setSelectedLessonSheetId, setSelectedTopics,setRoadTestTopics }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();

    const {loading, success, error, GetAllInCarSheetLessonData} = useSelector((state)=> state.Students.InCarSheet.getAllInCarSheetLesson)
    // const [modal, setModal ]= useState({
    //     addRoadTestEvaluation: false,
    //     view: false
    // });

    useEffect(()=>{
        dispatch(GetAllInCarSheetLessonThunk({studentId}));
        setSelectedTopics([]);
        setRoadTestTopics([]);
    },[]);

    const handleShowInCarReport = (second) => { 
        setPage({view: false, showInCarReport: true})
    }

    return (
        <React.Fragment>
            { loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
            : (
                <>
                    <Head title="Student In-Car Sheet | Admin Portal" />
                    <BlockHead size="sm">
                        <BlockBetween>
                            <BlockHeadContent className='d-flex justify-content-between w-100'>
                                <BlockTitle page>In-Car Sheet</BlockTitle>
                                {GetAllInCarSheetLessonData?.inCarSheetFilled && 
                                    <Button className='btn-dim' color='primary' onClick={handleShowInCarReport}> View In-Car Report</Button>
                                }
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                    {GetAllInCarSheetLessonData?.lessons?.map((lessonData, index)=>{
                        return (
                            <div 
                                className={`cursor_pointer `}
                                onClick = {()=> {
                                            setSelectedLessonId(lessonData?._id);
                                            setSelectedLessonSheetId(lessonData?.lessonSheetId);
                                            lessonData?.isRoadTest ? 
                                                ( lessonData?.lessonSheetFilled ? setModal({view: true}) : setModal({addRoadTestEvaluation: true}))
                                                :   !GetAllInCarSheetLessonData?.inCarSheetFilled ?
                                                    setPage({inCarReport: true})
                                                    : !lessonData?.lessonSheetFilled 
                                                        ? setPage({add: true})
                                                        : setPage({edit: true})
                                        }
                                } 
                            >
                                <Card className="card-bordered w-100 mb-1">
                                    <CardBody className="card-inner">
                                            <CardText tag="p" style={{ color: "#8094ae", marginBottom: "0.2rem" }}>
                                                <div className='d-flex justify-content-between'>
                                                    {lessonData?.isRoadTest ?
                                                        <h6>Road Test  </h6>
                                                    : 
                                                        <h6>Lesson { lessonData?.studentLessonNumber } / {lessonData?.totalLessons} </h6>
                                                    }
                                                    <span
                                                        className={`d-flex align-items-center badge badge-dim badge-${
                                                            lessonData?.lessonSheetFilled ?"success":  "warning" } `}
                                                    >
                                                        {lessonData?.lessonSheetFilled ? "Completed": "Outstanding"}
                                                    </span>
                                                </div>
                                            </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                    })}     
                </>
            )}
        </React.Fragment>
    )
}

export default ShowAllInCarLesson;