import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentPersonalDetailThunk = createAsyncThunk(
  "GetStudentPersonalDetail/GetStudentPersonalDetailThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.StudentsEndPoint.GetStudentPersonalDetail}?studentId=${studentId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentPersonalDetailSlice = createSlice({
  name: "GetStudentPersonalDetail",
  initialState: {
    studentPersonalDetail: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentPersonalDetailData: (state, action) => {
      state.studentPersonalDetail = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetStudentPersonalDetailThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentPersonalDetailThunk.fulfilled]: (state, action) => {
      state.studentPersonalDetail = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentPersonalDetailThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetStudentPersonalDetailSlice.reducer;

export const { ResetStudentPersonalDetailData } = GetStudentPersonalDetailSlice.actions;

export { GetStudentPersonalDetailThunk };
