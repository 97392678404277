import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Spinner } from "reactstrap/es";
import { useHistory, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, DropdownItem } from "reactstrap";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon
} from "../../components/Component";
import { GetInstructorStudentsThunk } from "../../redux/InstructorSlice/GetInstructorStudents";

const InstructorStudents = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { instructorId } = useParams();

  const { loading, instructorStudentData } = useSelector(
    (state) => state.instructors.getInstructorStudent
  );

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");


  // ********************************** Search and Pagination start ***********************************//

  const listingsPerPage = 10;
  const [allListing, setListing] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const pagesVisited = pageNumber * listingsPerPage;
  const pageCount = Math.ceil(
    allListing.filter((listing) => {
      if (onSearchText === "") {
        return listing;
      } else if (
        listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        listing.type.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return listing;
      }
      return false;
    }).length / listingsPerPage
  );

  const displayListing = allListing
    .filter((listing) => {
      if (onSearchText === "") {
        return listing;
      } else if (
        listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        listing.phoneNumber.toLowerCase().includes(onSearchText.toLowerCase()) ||
        listing.type.toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return listing;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + listingsPerPage);

  useEffect(() => {
    setListing(instructorStudentData);
  }, [instructorStudentData]);

  useEffect(() => {
    setPageNumber(0);
  }, [onSearchText]);

  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    dispatch(GetInstructorStudentsThunk({ instructorId: instructorId }));
  }, [dispatch, instructorId]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to handle student view details

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);


  // Change Page
  const paginate = ({ selected }) => setPageNumber(selected);

  return (
    <React.Fragment>
      <Head title="Instructor's Student"></Head>
      <Content>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Instructor's Student</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>You have total {instructorStudentData.length} students.</p>
                  </BlockDes>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Card className="card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="card-title-group">
                      <div className="card-title">
                        <h5 className="title">All Students</h5>
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar">
                          <li>
                            <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                              <Icon name="search"></Icon>
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                        <div className="search-content">
                          <Button
                            className="search-back btn-icon toggle-search"
                            onClick={() => {
                              setSearchText("");
                              toggle();
                            }}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder="Search "
                            value={onSearchText}
                            onChange={(e) => onFilterChange(e)}
                          />
                          <Button className="search-submit btn-icon">
                            <Icon name="search"></Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-id">
                            <span className="">#</span>
                          </th>
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Name</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-md-none">Package</span>
                              <span className="d-none d-md-block">
                                <span>Phone</span>
                                <span>Lessons</span>
                              </span>
                            </span>
                          </th>
                          <th className="tb-tnx-action">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayListing.length > 0
                          ? displayListing?.map((studentDetail, index) => {
                            return (
                              <tr key={studentDetail.id} className="tb-tnx-item">
                                <td className="tb-tnx-id">
                                  <a
                                    href="#ref"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <span>{index + 1}</span>
                                  </a>
                                </td>
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="title">{studentDetail.fullName}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">{studentDetail.phoneNumber}</span>
                                    <span className="date">{studentDetail.lessons}</span>
                                  </div>
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <ul className="link-list-plain">
                                        <li>
                                          <DropdownItem
                                            onClick={() => history.push(`/students/${studentDetail._id}`)}
                                          >
                                            View Details
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#print"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            Delete Student
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#print"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            Change Password
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })
                          :
                          <div className="text-center">
                            <span className="text-silent">No data found</span>
                          </div>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="card-inner">
                    <ReactPaginate
                      breakLabel="..."
                      breakClassName="pagination-btns"
                      onPageChange={(number) => paginate(number)}
                      // onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel="<<  Prev "
                      renderOnZeroPageCount={null}
                      nextLabel="Next >>"
                      previousClassName="pagination-next"
                      nextClassName="pagination-next"
                      activeClassName="pagination-active"
                      pageClassName="pagination mt-0"
                      pageLinkClassName="pagination-btns"
                      containerClassName="pagination justify-content-start"
                    />
                  </div>
                </div>
              </Card>
            </Block>
          </>
        )}

        {/* <Modal isOpen={viewModal} toggle={() => setViewModal(false)} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setViewModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="nk-modal-head">
              <h4 className="nk-modal-title title">
                Order <small className="text-primary">#{detail.ref}</small>
              </h4>
            </div>
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">Order ID</span>
                  <span className="caption-text">{detail.ref}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Bill </span>
                  <span className="caption-text text-break">{detail.bill}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Order Fee</span>
                  <span className="caption-text">$ {detail.total}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Status</span>
                  <Badge
                    color={detail.status === "Paid" ? "success" : detail.status === "Due" ? "warning" : "danger"}
                    size="md"
                  >
                    {detail.status}
                  </Badge>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Issue Date</span>
                  <span className="caption-text"> {detail.issue}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">Due Date</span>
                  <span className="caption-text"> {detail.due}</span>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal> */}
      </Content>
    </React.Fragment>
  );
};

export default InstructorStudents;
