import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UpdateReferralPromotionStatusThunk = createAsyncThunk(
  "UpdateReferralPromotionStatus/UpdateReferralPromotionStatusThunk",
  async ({ promoId, isLMS }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.PromotionsEndPoint.UpdateReferralPromotionStatus}?promoId=${promoId}`,
        { isLMS },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const UpdateReferralPromotionStatusSlice = createSlice({
  name: "UpdateReferralPromotionStatus",
  initialState: {
    UpdateReferralPromotionStatusData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUpdateReferralPromotionStatusData: (state, action) => {
      state.UpdateReferralPromotionStatusData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },

  extraReducers: {
    [UpdateReferralPromotionStatusThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UpdateReferralPromotionStatusThunk.fulfilled]: (state, action) => {
      state.UpdateReferralPromotionStatusData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UpdateReferralPromotionStatusThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default UpdateReferralPromotionStatusSlice.reducer;

export const { ResetUpdateReferralPromotionStatusData } = UpdateReferralPromotionStatusSlice.actions;

export { UpdateReferralPromotionStatusThunk };
