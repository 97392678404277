import React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Button, Col, FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import {
  AddSeasonalPromotionThunk,
  ResetAddSeasonalPromotionData,
} from "../../../redux/Promotions/AddSeasonalPromotionSlice";
import { GetAllPromotionsThunk } from "../../../redux/Promotions/GetAllPromotionsSlice";

const typeOption = [
  { label: "%", value: true },
  { label: "$", value: false },
];

const AddSeasonalPromotionModal = ({ modal, setModal }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    promoCode: "",
    percentage: false,
    amount: "",
    type: "",
  });

  const {
    loading: AddSeasonalPromotionLoading,
    success: AddSeasonalPromotionSuccess,
    error: AddSeasonalPromotionError,
    errorMessage: AddSeasonalPromotionErrorMessage,
  } = useSelector((state) => state.promotions.addSeasonalPromotion);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddSeasonalPromotion = () => {
    dispatch(
      AddSeasonalPromotionThunk({
        promoCode: formData.promoCode,
        percentage: formData.percentage,
        amount: formData.amount,
        type: formData.type,
      })
    );
  };

  if (AddSeasonalPromotionSuccess) {
    toast.success("Promo Code has been added successfully.");
    dispatch(ResetAddSeasonalPromotionData());
    dispatch(GetAllPromotionsThunk());
    setModal({ addSeasonalPromotion: false });
  }

  if (AddSeasonalPromotionError) {
    toast.error(AddSeasonalPromotionErrorMessage);
    dispatch(ResetAddSeasonalPromotionData());
    dispatch(GetAllPromotionsThunk());
    setModal({ addSeasonalPromotion: false });
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setModal({ addSeasonalPromotion: false })}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal({ addSeasonalPromotion: false });
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title mb-5">Add Promotion</h5>

            <div className="tab-content px-3">
              <div className={`tab-pane active`} id="personal">
                <Row className="gy-4">
                  <form>
                    <Row className="gy-4">
                      <Col md="6">
                        <FormGroup>
                          <label className="form-label" htmlFor="promoCode">
                            Promo Code
                          </label>
                          <input
                            type="text"
                            id="promoCode"
                            className="form-control"
                            name="promoCode"
                            onChange={(e) => onInputChange(e)}
                            placeholder="Enter Promo Code"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <label className="form-label" htmlFor="amount">
                          Amount
                        </label>
                        <input
                          type="number"
                          id="amount"
                          className="form-control"
                          name="amount"
                          onChange={(e) => onInputChange(e)}
                          placeholder="Enter Amount"
                          required
                        />
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <label className="form-label" htmlFor="Type">
                            Type
                          </label>
                          <RSelect
                            defaultValue={{ value: false, label: "$" }}
                            options={typeOption}
                            placeholder="Select a Type"
                            onChange={(e) => setFormData({ ...formData, percentage: e.value })}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button
                              onClick={handleAddSeasonalPromotion}
                              disabled={AddSeasonalPromotionLoading}
                              color="primary"
                              size="md"
                              type="submit"
                            >
                              <span>{AddSeasonalPromotionLoading ? <Spinner size="md" /> : "Add Promotion"} </span>
                            </Button>
                          </li>
                          <li>
                            <a
                              href="#cancel"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setModal({ addSeasonalPromotion: false });
                              }}
                              className="link link-light"
                            >
                              Cancel
                            </a>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </form>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddSeasonalPromotionModal;
