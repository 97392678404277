import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const GetDroppedStudentsThunk = createAsyncThunk(
    "DroppedStudents/GetDroppedStudentsThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.DroppedStudents.GetDroppedStudents, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const GetDroppedStudentsSlice = createSlice({
    name: "DroppedStudents",
    initialState: {
        droppedStudentsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDroppedStudentsData: (state, action) => {
            state.droppedStudentsData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetDroppedStudentsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetDroppedStudentsThunk.fulfilled]: (state, action) => {
            state.droppedStudentsData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetDroppedStudentsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
        },
    },
});

export default GetDroppedStudentsSlice.reducer;

export const { ResetDroppedStudentsData } = GetDroppedStudentsSlice.actions;

export { GetDroppedStudentsThunk };
