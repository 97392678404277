import { Formik } from 'formik';
import React from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { DeleteInstructorReviewsThunk, ResetDeleteInstructorReviewsData } from '../../../redux/InstructorSlice/DeleteInstructorReviewSlice';
import { GetInstructorReviewsThunk } from '../../../redux/InstructorSlice/GetInstructorReviews';

const DeleteInstructorReviewModal = ({ openModal, setOpenModal, instructorId, reviewId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.instructors.deleteInstructorReview);


    const handleDeleteReview = (second) => {
        dispatch(DeleteInstructorReviewsThunk({ reviewId }))
    }

    if (success) {
        toast.success("Review has been deleted successfully.");
        dispatch(ResetDeleteInstructorReviewsData());
        dispatch(GetInstructorReviewsThunk({ instructorId: instructorId }));
        setOpenModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetDeleteInstructorReviewsData());
        dispatch(GetInstructorReviewsThunk({ instructorId: instructorId }));
        setOpenModal(false);
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={openModal.delete}
                toggle={() => setOpenModal({ delete: false })}
                className="modal-dialog-centered"
                size="md"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();

                            setOpenModal({ delete: false });
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h4 className="title">Delete Review</h4>
                        <div className="mt-4">
                            <Formik>
                                <Form className="row gy-4 mt-4 mb-5">
                                    <Col md="12">
                                        <div>
                                            <h6>Are you sure you want to delete instructor review?</h6>
                                        </div>
                                    </Col>
                                    <Col size="12">
                                        <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button onClick={handleDeleteReview} disabled={loading} color="danger" size="md" type="submit">
                                                    <span>{loading ? <Spinner size="sm" /> : "Delete Review"} </span>
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setOpenModal({ delete: false });
                                                    }}
                                                    className="link link-light"
                                                >
                                                    Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default DeleteInstructorReviewModal