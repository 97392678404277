import React, { useEffect, useState } from 'react'
import { Card, DropdownItem, DropdownMenu, DropdownToggle, Spinner, UncontrolledDropdown } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { GetStudentRefundsThunk } from '../../../redux/StudentsSlice/GetStudentRefundsSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ChangeStatusModal from './ChangeStatusModal';

const Refunds = () => {
    const { studentId } = useParams();
    const dispatch = useDispatch();

    const { loading, studentRefundsData} = useSelector((state)=> state.Students.getStudentRefunds);

    const [selectedRefundData, setSelectedRefundData] = useState();
    const [modal, setModal] = useState({
        changeStatus: false
    })

    useEffect(()=>{
        dispatch(GetStudentRefundsThunk({ studentId }));
    }, [dispatch, studentId]);

    return (
        <React.Fragment>
            {loading ? 
                <div className='d-flex justify-content-center align-items-center vh-100' >
                    <Spinner style={{color: "#37a2d0"}} />
                </div>
            : (
                <Card className="card-bordered card-stretch">
                    <div className="card-inner-group">
                        <div className="card-inner p-0">
                        <table className="table table-tranx">
                            <thead>
                            <tr className="tb-tnx-head">
                            <th className="tb-tnx-id">
                                    <span className="">#</span>
                                </th>
                                <th className="tb-tnx-info">
                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                        <span>Stripe PID</span>
                                    </span>
                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                        <span className="d-none d-md-block">
                                            <span>Date</span>
                                            <span>Amount</span>
                                        </span>
                                    </span>
                                </th>
                                <th className="tb-tnx-amount is-alt">
                                    <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                                </th>
                                <th className="tb-tnx-action">
                                    <span>Action</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                {studentRefundsData?.refunds?.map((refundData, index)=> {
                                    return (
                                        <tr className="tb-tnx-item" style={{backgroundColor: `${refundData?.homePickup ? "#7460FB20": "#36A0CD15"}`}}>
                                            <td className="tb-tnx-id" >
                                                <div
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                    }}
                                                >
                                                    <span>{index + 1}</span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-info">
                                                <div className="tb-tnx-desc">
                                                    <span className="title"> {refundData?.stripePID}</span>
                                                </div>
                                                <div className="tb-tnx-date">
                                                    <span className="date">
                                                        {moment.utc(refundData?.refundDate).format("DD-MM-YYYY")}
                                                    </span>
                                                    <span className="date">
                                                        ${refundData?.refundAmount}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-amount is-alt">
                                                <div className="tb-tnx-status">
                                                    <span className={`badge badge-dot badge-${ refundData?.isRefunded? "success": "danger" }`}>
                                                        {refundData?.isRefunded ? "Paid": "Pending"}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="tb-tnx-action">
                                                <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                                    <Icon name="more-h"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <ul className="link-list-plain">
                                                        <li>
                                                            <DropdownItem
                                                            onClick={(ev) => {
                                                                // setLessonsDetail(lessonDetail);
                                                                // dispatch(GetLessonDetailThunk({ lessonId: lessonDetail._id }));
                                                                setSelectedRefundData(refundData)
                                                                setModal({ changeStatus: true });
                                                            }}
                                                            >
                                                            Mark as Paid
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        </div>
                    </div>
                </Card>
            )}
            {modal.changeStatus && <ChangeStatusModal modal={modal} setModal={()=> setModal({ changeStatus: false })} selectedRefundData={selectedRefundData} /> }
        </React.Fragment>
    )
}

export default Refunds;
