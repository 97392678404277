import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentNotesThunk = createAsyncThunk(
  "GetStudentNotes/GetStudentNotesThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.GetStudentNotes}?studentId=${studentId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentNotesSlice = createSlice({
  name: "GetStudentNotes",
  initialState: {
    studentNotesData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentNotesData: (state, action) => {
      state.studentNotesData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetStudentNotesThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentNotesThunk.fulfilled]: (state, action) => {
      state.studentNotesData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentNotesThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetStudentNotesSlice.reducer;

export const { ResetStudentNotesData } = GetStudentNotesSlice.actions;

export { GetStudentNotesThunk };
