import { combineReducers } from "@reduxjs/toolkit";
import GetAllInvoicesSlice from "./GetAllInvoicesSlice";
import ChangeInvoiceStatusSlice from "./ChangeInvoiceStatusSlice";
import EmailInvoiceSlice from "./EmailInvoiceSlice";



export const invoicesReducers = combineReducers({
    getAllInvoices: GetAllInvoicesSlice,
    changeInvoiceStatus: ChangeInvoiceStatusSlice,
    emailInvoice: EmailInvoiceSlice
})