import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student notes
const AddStudentNotesThunk = createAsyncThunk(
    "AddStudentNotes/AddStudentNotesThunk",
    async ({ studentId, notes }, { rejectWithValue }) => {
        try {
        const response = await axios.post(
            `${ApiEndpoints.StudentsEndPoint.AddStudentNotes}?studentId=${studentId}`,
            { note: notes },
            {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const AddStudentNotessSlice = createSlice({
    name: "AddStudentNotess",
    initialState: {
        AddStudentNotesData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetAddStudentNotesData: (state, action) => {
        state.AddStudentNotesData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [AddStudentNotesThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [AddStudentNotesThunk.fulfilled]: (state, action) => {
        state.AddStudentNotesData = [...state.AddStudentNotesData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [AddStudentNotesThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default AddStudentNotessSlice.reducer;

export const { ResetAddStudentNotesData } = AddStudentNotessSlice.actions;

export { AddStudentNotesThunk };
