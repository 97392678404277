import React, { useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import GeoMaps from './GeoMaps';
import Content from '../../layout/content/Content';
import { AddGeoFencingThunk, ResetAddGeoFencingData } from '../../redux/GeoFencingSlice/AddGeoFencingSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const AddGeoMap = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        fullName: ""
    });

    const [state, setState] = useState([])
    const { paths } = state

    const {loading, success, error, errorMessage} = useSelector((state)=> state.geoFencing.addGeoFencing)

    const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddGeoFencing = () => { 
        dispatch(AddGeoFencingThunk({
            name: formData.fullName, geoFencingPoints: paths
        }))
    }

    if(success){
        toast.success("Geo Fencing has been added successfully.");
        dispatch(ResetAddGeoFencingData());
        history.push('/geo_fencing');
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetAddGeoFencingData());
    }

    const { errors, register, handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Content>
                <h3> Add Geo Fence</h3>
                {/* {loading ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                    </div>
                :( */}
                    <Row>
                        <Col xl="4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddGeoFencing)}>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Full Name</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="fullName"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.fullName}
                                            placeholder="Enter Name"
                                        />
                                        {errors.fullName && <span className="invalid">{errors.fullName.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button disabled={!(paths && formData.fullName !== "" ) || loading } color="primary" size="md" type="submit">
                                                {loading ? <Spinner size="sm" /> : "Add Geo Fence"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a href="/geo_fencing/" className="link link-light">
                                                Go Back
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </Col>
                        <Col xl="8">
                            <GeoMaps
                                // apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                paths={paths}
                                point={paths => setState({ paths })}
                            />
                        </Col>
                </Row>
                {/* )
                } */}
            </Content>
        </React.Fragment>
    )
}

export default AddGeoMap;
