import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const GetAllPickupPointsThunk = createAsyncThunk(
  "AllPickupPoints/GetAllPickupPointsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ApiEndpoints.PickupPoints.GetAllPickupPoints, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      const errorMessage = error?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errorMessage);
    }
  }
);

const GetAllPickupPointsSlice = createSlice({
  name: "AllPickupPoints",
  initialState: {
    pickupPointsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetPickupPointsData: (state, action) => {
      state.pickupPointsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetAllPickupPointsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetAllPickupPointsThunk.fulfilled]: (state, action) => {
      state.pickupPointsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetAllPickupPointsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetAllPickupPointsSlice.reducer;

export const { ResetPickupPointsData } = GetAllPickupPointsSlice.actions;

export { GetAllPickupPointsThunk };
