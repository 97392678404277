import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ChangeStudentPickupLocationThunk = createAsyncThunk(
  "ChangeStudentPickupLocation/ChangeStudentPickupLocationThunk",
  async ({ lessonId, pickupLocation, latitude, longitude }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.ChangeStudentPickupLocation}?lessonId=${lessonId}`,
        { pickupLocation, latitude, longitude },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const ChangeStudentPickupLocationSlice = createSlice({
  name: "ChangeStudentPickupLocation",
  initialState: {
    ChangeStudentPickupLocationData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetChangeStudentPickupLocationData: (state, action) => {
      state.ChangeStudentPickupLocationData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ChangeStudentPickupLocationThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ChangeStudentPickupLocationThunk.fulfilled]: (state, action) => {
      state.ChangeStudentPickupLocationData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ChangeStudentPickupLocationThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ChangeStudentPickupLocationSlice.reducer;

export const { ResetChangeStudentPickupLocationData } = ChangeStudentPickupLocationSlice.actions;

export { ChangeStudentPickupLocationThunk };
