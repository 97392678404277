import { combineReducers } from "@reduxjs/toolkit";
import AddGeoFencingSlice from "./AddGeoFencingSlice";
import DeleteGeoFencingSlice from "./DeleteGeoFencingSlice";
import GetAllGeoFencingSlice from "./GetAllGeoFencingSlice";
import UpdateGeoFencingSlice from "./UpdateGeoFencingSlice";
import ViewAllGeoFencingSlice from "./ViewAllGeoFencingSlice";


export const GeoFencingReducers = combineReducers({
    getAllGeoFencing: GetAllGeoFencingSlice,
    viewAllGeoFencing: ViewAllGeoFencingSlice,
    addGeoFencing: AddGeoFencingSlice,
    updateGeoFencing: UpdateGeoFencingSlice,
    deleteGeoFencing: DeleteGeoFencingSlice
});
