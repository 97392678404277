import React, { useState, useEffect } from "react";

import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card, Spinner } from "reactstrap";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, } from "../../components/Component";
import { GetLmsStudentsThunk } from "../../redux/LmsStudentSlice/GetLmsStudentSlice";
import { GetDroppedStudentsThunk } from "../../redux/DroppedStudentsSlice/GetDroppedStudentsSlice";

const LMSstudent = () => {
    const dispatch = useDispatch();

    const { loading, droppedStudentsData } = useSelector((state) => state.droppedStudent.getDroppedStudents);

    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");

    // ********************************** Search and Pagination start ***********************************//

    const listingsPerPage = 10;
    const [allListing, setListing] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const pagesVisited = pageNumber * listingsPerPage;
    const pageCount = Math.ceil(
        allListing.filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                // listing.postalCode.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.creationDate.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        }).length / listingsPerPage
    );

    const displayListing = allListing
        .filter((listing) => {
            if (onSearchText === "") {
                return listing;
            } else if (
                // listing.postalCode.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.fullName.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.email.toLowerCase().includes(onSearchText.toLowerCase()) ||
                listing.creationDate.toLowerCase().includes(onSearchText.toLowerCase())
            ) {
                return listing;
            }
            return false;
        })
        .slice(pagesVisited, pagesVisited + listingsPerPage);

    useEffect(() => {
        const lmsStudents =  droppedStudentsData?.filter((droppedStudent, index)=> droppedStudent?.packageFlow === "knowledge_test");
        setListing(lmsStudents);
    }, [droppedStudentsData]);

    useEffect(() => {
        setPageNumber(0);
    }, [onSearchText]);

    // Change Page
    const paginate = ({ selected }) => setPageNumber(selected);

    // ********************************** Pagination End ***********************************//


    useEffect(() => {
        dispatch(GetLmsStudentsThunk());
        dispatch(GetDroppedStudentsThunk());

    }, [dispatch]);


    // onChange function for searching name
    const onFilterChange = (e) => {
        setSearchText(e.target.value);
    };

    // function to toggle the search option
    const toggle = () => setonSearch(!onSearch);

    return (
        <React.Fragment>
            <Head title="LMS Students - Admin portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>LMS Students</BlockTitle>
                            {/* <BlockDes className="text-soft">
                                <p>You have total {droppedStudentsData?.length} students.</p>
                            </BlockDes> */}
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Spinner />
                    </div>
                ) : (
                    <Block>
                        <Card className="card-bordered card-stretch">
                            <div className="card-inner-group">
                                <div className="card-inner">
                                    <div className="card-title-group">
                                        <div className="card-title">
                                            <h5 className="title">LMS Students</h5>
                                        </div>
                                        <div className="card-tools mr-n1">
                                            <ul className="btn-toolbar">
                                                <li>
                                                    <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                                                        <Icon name="search"></Icon>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                                            <div className="search-content">
                                                <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                                                    <Icon name="arrow-left"></Icon>
                                                </Button>
                                                <input
                                                    type="text"
                                                    className="form-control border-transparent form-focus-none"
                                                    placeholder="Search"
                                                    value={onSearchText}
                                                    onChange={(e) => onFilterChange(e)}
                                                />
                                                <Button className="search-submit btn-icon">
                                                    <Icon name="search"></Icon>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-inner p-0">
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                                <th className="tb-tnx-id">
                                                    <span className="">#</span>
                                                </th>
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Email</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>Full Name</span>
                                                            <span>Phone Number</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                <th className="tb-tnx-amount is-alt">
                                                    <span className="tb-tnx-total">Postal Code</span>
                                                    <span className="tb-tnx-status d-none d-md-inline-block">Creation Date</span>
                                                </th>
                                                <th className="tb-tnx-action">
                                                    <span>Signed Up</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayListing?.length > 0
                                                ? displayListing?.filter((droppedStudent, index)=> droppedStudent?.packageFlow === "knowledge_test")?.map((droppedStudent, index) => {
                                                    return (
                                                        <tr key={droppedStudent.id} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{pageNumber * listingsPerPage + index + 1}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className="title">{droppedStudent?.email}</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className="date">{droppedStudent?.fullName}</span>
                                                                    <span className={`date `}>{droppedStudent?.phoneNumber}</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-amount is-alt">
                                                                <div className="tb-tnx-total">
                                                                    <span className="amount">{droppedStudent.postalCode}</span>
                                                                </div>
                                                                <div className="tb-tnx-status">

                                                                    {moment(droppedStudent?.creationDate).format("DD-MM-YYYY")}
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-action">
                                                                <span className={`ml-2 badge badge-dot badge-${droppedStudent?.signedUp ? "success" : "danger"}`} >{droppedStudent?.signedUp ? "Yes" : "No"} </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-inner">
                                    {displayListing?.length > 0 ? (
                                        <ReactPaginate
                                            breakLabel="..."
                                            breakClassName="pagination-btns"
                                            onPageChange={(number) => paginate(number)}
                                            // onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={pageCount}
                                            previousLabel="<<  Prev "
                                            renderOnZeroPageCount={null}
                                            nextLabel="Next >>"
                                            previousClassName="pagination-next"
                                            nextClassName="pagination-next"
                                            activeClassName="pagination-active"
                                            pageClassName="pagination mt-0"
                                            pageLinkClassName="pagination-btns"
                                            containerClassName="pagination justify-content-start"
                                        />
                                    ) : (
                                        <div className="text-center">
                                            <span className="text-silent">No data found</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </Block>
                )}

            </Content>

        </React.Fragment>
    )
}

export default LMSstudent;