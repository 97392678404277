import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteLessonThunk = createAsyncThunk(
  "DeleteLesson/DeleteLessonThunk",
  async ({ lessonId, isRefund }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${ApiEndpoints.LesssonsEndPoint.DeleteLessons}?lessonId=${lessonId}&isRefund=${isRefund}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const DeleteLessonSlice = createSlice({
  name: "DeleteLesson",
  initialState: {
    DeleteLessonData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDeleteLessonData: (state, action) => {
      state.DeleteLessonData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [DeleteLessonThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DeleteLessonThunk.fulfilled]: (state, action) => {
      state.DeleteLessonData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [DeleteLessonThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DeleteLessonSlice.reducer;

export const { ResetDeleteLessonData } = DeleteLessonSlice.actions;

export { DeleteLessonThunk };
