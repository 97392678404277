import { combineReducers } from "@reduxjs/toolkit";
import AddPickupPointSlice from "./AddPickupPointSlice";
import GetAllPickupPointsSlice from "./GetAllPickupPoints";
import RemovePickupPointsSlice from "./RemovePickupPointsSlice";
import UpdatePickupPointSlice from "./UpdatePickupPointSlice";

export const pickupPointsReducers = combineReducers({
  getAllPickupPoints: GetAllPickupPointsSlice,
  removePickupPoint: RemovePickupPointsSlice,
  addPickupPoint: AddPickupPointSlice,
  updatePickupPoint: UpdatePickupPointSlice
});
