import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentRoadTestThunk = createAsyncThunk(
  "GetStudentRoadTest/GetStudentRoadTestThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.GetStudentRoadTest}?studentId=${studentId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentRoadTestSlice = createSlice({
  name: "GetStudentRoadTest",
  initialState: {
    studentRoadTestData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentRoadTestData: (state, action) => {
      state.studentRoadTestData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetStudentRoadTestThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentRoadTestThunk.fulfilled]: (state, action) => {
      state.studentRoadTestData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentRoadTestThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.studentRoadTestData = [];
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetStudentRoadTestSlice.reducer;

export const { ResetStudentRoadTestData } = GetStudentRoadTestSlice.actions;

export { GetStudentRoadTestThunk };
