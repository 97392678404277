import React from 'react'
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import moment from 'moment';

import { ResetUpdatedInstructorScheduleData, UpdateInstructorScheduleThunk } from '../../../redux/InstructorSlice/UpdateInstructorScheduleSlice';
import { DeleteInstructorAvailabilityThunk, ResetDeletedInstructorAvailabilityData } from '../../../redux/InstructorSlice/DeleteInstructorAvailabilitySlice';
import { GetInstructorScheduleThunk } from '../../../redux/InstructorSlice/GetInstructorScheduleSlice';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../components/Component';

const UpdateInstructorSlot = ({modal, setModal, goBack, GetInstructorScheduleLoading, scheduleTime, setScheduleTime, oldLocations, setOldLocations, setSelectedLocations, oldTimeSlot, selectedDay, selectedLocations}) => {
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  console.log('scheduleTime', scheduleTime)
  console.log('oldTimeSlot', oldTimeSlot)
  const {
    loading: DeleteInstructorScheduleLoading,
    success: DeleteInstructorScheduleSuccess,
    error: DeleteInstructorScheduleError,
    errorMessage: DeleteInstructorScheduleErrorMessage,
  } = useSelector((state) => state.instructors.deleteInstrcutorAvailability);

  const {
    loading: UpdateInstructorScheduleLoading,
    success: UpdateInstructorScheduleSuccess,
    error: UpdateInstructorScheduleError,
    errorMessage: UpdateInstructorScheduleErrorMessage,
  } = useSelector((state) => state.instructors.updateInstructorSchedule);

  // submit update time schedule
  const handleSubmitUpdateAvailability = () => {
    const startAddOnHours = moment(scheduleTime?.startTime).toDate().getHours();
    const startAddOnMinutes = moment(scheduleTime?.startTime).toDate().getMinutes();
    const endAddOnHours = moment(scheduleTime?.endTime).toDate().getHours();
    const endAddOnMinutes = moment(scheduleTime?.endTime).toDate().getMinutes();

    dispatch(
      UpdateInstructorScheduleThunk({
        oldStartTime: oldTimeSlot?.startTime,
        oldEndTime: oldTimeSlot?.endTime,
        newStartTime: moment(scheduleTime?.startTime)
          .startOf("year").year(2022)
          .add("hours", startAddOnHours)
          .add("minutes", startAddOnMinutes)
          .utcOffset(0, true)
          .toISOString(),
        newEndTime: moment(scheduleTime?.endTime)
          .startOf("year").year(2022)
          .add("hours", endAddOnHours)
          .add("minutes", endAddOnMinutes)
          .utcOffset(0, true)
          .toISOString(),
        day: selectedDay,
        weekly: true,
        locations: selectedLocations,
        instructorId,
      })
    );
  };

  // delete time slot
  const handleDeleteAvailability = (id) => {
    dispatch(
      DeleteInstructorAvailabilityThunk({
        startTime: oldTimeSlot?.startTime,
        endTime: oldTimeSlot?.endTime,
        day: selectedDay,
        instructorId,
      })
    );
  };

  if (UpdateInstructorScheduleSuccess) {
    toast.success("Instructor Time Slot has been updated successfully.");
    dispatch(ResetUpdatedInstructorScheduleData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal();
  }

  if (DeleteInstructorScheduleSuccess) {
    toast.success("Instructor Time Slot has been deleted successfully.");
    dispatch(ResetDeletedInstructorAvailabilityData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal();
  }

  if (UpdateInstructorScheduleError) {
    toast.error(UpdateInstructorScheduleErrorMessage);
    dispatch(ResetUpdatedInstructorScheduleData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal();
  }

  if (DeleteInstructorScheduleError) {
    toast.error(DeleteInstructorScheduleErrorMessage);
    dispatch(ResetDeletedInstructorAvailabilityData());
    dispatch(GetInstructorScheduleThunk({ instructorId }));
    setModal();
  }

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
            <BlockHeadContent>
            <BlockTitle page>Update Schedule</BlockTitle>
            </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      {GetInstructorScheduleLoading || DeleteInstructorScheduleLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <form className="form-validate is-alter" >

          <Row className="gx-4 gy-3">
            <Col sm="12">
              <div className=' mb-5'>
                <Button onClick={()=> goBack()}> 
                    <Icon name="back-arrow-fill"/>
                    {" "} Back
                </Button>
              </div>
            </Col>
            <Col sm="6">
              <FormGroup>
                <label className="form-label">Start Time</label>
                <Row className="gx-2" style={{ zIndex: "2" }}>
                  <div className="w-100" style={{ zIndex: "2" }}>
                    <div className="form-control-wrap has-timepicker" style={{ zIndex: "2" }}>
                      <DatePicker
                        style={{ zIndex: "2" }}
                        selected={scheduleTime?.startTime}
                        onChange={(date) => setScheduleTime({ ...scheduleTime, startTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <label className="form-label">End Time</label>
                <Row className="gx-2">
                  <div className="w-100">
                    <div className="form-control-wrap has-timepicker" style={{ opacity: 3 }}>
                      <DatePicker
                        selected={scheduleTime?.endTime}
                        onChange={(date) => setScheduleTime({ ...scheduleTime, endTime: date })}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="form-control date-picker"
                      />
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <label className="form-label">Locations</label>
                <Row className="gx-2">
                  <div className="w-100">
                    <div className="form-control-wrap">
                      <ul className="link-tidy ml-0">
                        {oldLocations.map((location, index) => {
                          return (
                            <>
                              {location && (
                                <li key={location?._id}>
                                  <div className="custom-control custom-control-sm custom-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={location.isChecked}
                                      name={location?.name}
                                      className="custom-control-input"
                                      onClick={(e) => {
                                        const difLocation = oldLocations.map((oldLoc)=>{
                                          const data = oldLoc?._id === e.target.value ? {_id:oldLoc?._id,name: oldLoc?.name, isChecked: e.target.checked }: oldLoc
                                          return data;
                                        });
                                        setOldLocations(difLocation);
                                        e.target.checked
                                        ? setSelectedLocations( selectedLoc => [...selectedLoc, location?._id])
                                        : setSelectedLocations(selectedLoc => selectedLoc.filter(id => id !== location._id))
                                        }}
                                      value={location?._id}
                                      id={location?.name}
                                    />
                                    <label className="custom-control-label" htmlFor={location?.name}>
                                      {location?.name}
                                    </label>
                                  </div>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Row>
              </FormGroup>
            </Col>
            <Col sm="12">
              <ul className="d-flex justify-content-between gx-4 mt-1">
                <li>
                  <Button
                    disabled={UpdateInstructorScheduleLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitUpdateAvailability();
                    }}
                    type="submit"
                    color="primary"
                  >
                    {UpdateInstructorScheduleLoading ? <Spinner /> : "Update Availibility"}
                  </Button>
                </li>
                <li>
                  <Button color="danger" className="btn-dim" onClick={handleDeleteAvailability}>
                    Delete
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </form>
      )}

    </React.Fragment>
  )
}

export default UpdateInstructorSlot;