import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteSeasonalPromotionThunk = createAsyncThunk(
  "DeleteSeasonalPromotion/DeleteSeasonalPromotionThunk",
  async ({ promoId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${ApiEndpoints.PromotionsEndPoint.DeleteSeasonalPromotion}?promoId=${promoId}`,

        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const DeleteSeasonalPromotionSlice = createSlice({
  name: "DeleteSeasonalPromotion",
  initialState: {
    DeleteSeasonalPromotionData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDeleteSeasonalPromotionData: (state, action) => {
      state.DeleteSeasonalPromotionData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },

  extraReducers: {
    [DeleteSeasonalPromotionThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DeleteSeasonalPromotionThunk.fulfilled]: (state, action) => {
      state.DeleteSeasonalPromotionData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [DeleteSeasonalPromotionThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DeleteSeasonalPromotionSlice.reducer;

export const { ResetDeleteSeasonalPromotionData } = DeleteSeasonalPromotionSlice.actions;

export { DeleteSeasonalPromotionThunk };
