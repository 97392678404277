import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Spinner, } from "reactstrap";
import {Block,BlockBetween,BlockDes,BlockHead,BlockHeadContent,BlockTitle,Icon } from "../../components/Component";
import Content from "../../layout/content/Content";
import DeleteGoeFencingModal from "./DeleteGoeFencingModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetAllGeoFencingThunk } from "../../redux/GeoFencingSlice/GetAllGeoFencingSlice";

const GeoFencing = () => {
    const dispatch = useDispatch();
    const {loading, error, errorMessage, geoFencingData} = useSelector((state)=> state.geoFencing.getAllGeoFencing);
    const history = useHistory();
    const [selectedId, setSelectedId] = useState('');
    const [modal, setModal] = useState({
        delete: false
    });

    useEffect(()=>{
        dispatch(GetAllGeoFencingThunk())
    },[])

    return (
        <React.Fragment>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Instructor's Geo Fencing</BlockTitle>
                            <BlockDes className="text-soft">
                                <p>You have total {geoFencingData.length} Geo fence.</p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        <ul className="nk-block-tools-opt">
                                <li>
                                    <Button color="primary" onClick={() => history.push(`/add_geo_fencing/`)} >
                                        <Icon name="plus" />
                                        <span>Add Geo Fence</span>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        <ul className="nk-block-tools-opt">
                                <li>
                                    <Button color="primary" onClick={() => history.push(`/view_all_geo_fencing/`)} >
                                        <span>View All Geo Fence</span>
                                    </Button>
                                </li>
                            </ul>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                    <Card className="card-bordered card-stretch">
                        <div className="card-inner-group">
                            <div className="card-inner">
                                <div className="card-title-group">
                                    <div className="card-title">
                                        <h5 className="title">Geo Fencing</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-inner p-0">
                                {loading? 
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                                    <Spinner />
                                    </div>
                                :
                                    (error || geoFencingData.length === 0) ? 
                                        <div className="text-center">
                                            <span className="text-silent">{ "No data Found"} </span>
                                        </div>
                                    : 
                                    <table className="table table-tranx">
                                        <thead>
                                            <tr className="tb-tnx-head">
                                                <th className="tb-tnx-id">
                                                    <span className="">#</span>
                                                </th>
                                                <th className="tb-tnx-info">
                                                    <span className="tb-tnx-desc d-none d-sm-inline-block">
                                                        <span>Name</span>
                                                    </span>
                                                    <span className="tb-tnx-date d-md-inline-block d-none">
                                                        <span className="d-none d-md-block">
                                                            <span>Edit Geo Fence</span>
                                                            <span>Delete Geo Fence</span>
                                                        </span>
                                                    </span>
                                                </th>
                                                {/* <th className="tb-tnx-action">
                                                <span>Signed Up</span>
                                            </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                geoFencingData?.geoFencing?.map((geoFence, index)=>{
                                                    return (
                                                        <tr key={1} className="tb-tnx-item">
                                                            <td className="tb-tnx-id">
                                                                <div>
                                                                    <span>{index + 1 }</span>
                                                                </div>
                                                            </td>
                                                            <td className="tb-tnx-info">
                                                                <div className="tb-tnx-desc">
                                                                    <span className="title">{ geoFence.name}</span>
                                                                </div>
                                                                <div className="tb-tnx-date">
                                                                    <span className="date">
                                                                        <Button className="primary" color="primary" onClick={() =>{ const encodedArray = encodeURIComponent(JSON.stringify(geoFence.fencingPoints)); history.push(`/edit_geo_fencing?_id=${geoFence._id}&name=${geoFence.name}&geoFencingPoints=${encodedArray}`)}} >
                                                                            Edit
                                                                        </Button>
                                                                    </span>
                                                                    <span className={`date `}>
                                                                        <Button className="primary" color="danger" onClick={() => { setSelectedId(geoFence._id); setModal({ delete: true });}}>
                                                                            Delete
                                                                        </Button>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>                                
                                }
                            </div>
                            <div className="card-inner">
                            </div>
                        </div>
                    </Card>
                </Block>
            </Content> 
            {modal.delete && <DeleteGoeFencingModal modal={modal} setModal={() => setModal({ delete: false })} _id={selectedId} />}
        </React.Fragment>
    )
}

export default GeoFencing;