import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetPackagesThunk = createAsyncThunk(
  "GetPackages/GetPackagesThunk",
  async ( _ , { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.GetPackages}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetPackagesSlice = createSlice({
  name: "GetPackages",
  initialState: {
    packagesData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetPackagesData: (state, action) => {
      state.packagesData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetPackagesThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetPackagesThunk.fulfilled]: (state, action) => {
      state.packagesData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetPackagesThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.packagesData = [];
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetPackagesSlice.reducer;

export const { ResetPackagesData } = GetPackagesSlice.actions;

export { GetPackagesThunk };
