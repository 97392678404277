import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const AddInstructorThunk = createAsyncThunk(
  "AddInstructors/AddInstructorThunk",
  async ({ instructorImage, fullName, phoneNumber, email, password, locationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.AddInstructors}?locationId=${locationId}`,
        {
          instructorImage,
          fullName,
          email,
          password,
          phoneNumber,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const AddInstructorsSlice = createSlice({
  name: "AddInstructors",
  initialState: {
    AddInstructorData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetAddInstructorData: (state, action) => {
      state.AddInstructorData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [AddInstructorThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [AddInstructorThunk.fulfilled]: (state, action) => {
      state.AddInstructorData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [AddInstructorThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default AddInstructorsSlice.reducer;

export const { ResetAddInstructorData } = AddInstructorsSlice.actions;

export { AddInstructorThunk };
