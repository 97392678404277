import React from 'react'
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useDispatch } from 'react-redux';
import { AddLessonInCarSheetThunk, ResetAddLessonInCarSheetData } from '../../../redux/StudentsSlice/InCarSheetSlice/AddLessonInCarSheetSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import toast from 'react-hot-toast';
import { AddMultiLessonInCarSheetThunk, ResetAddMultiLessonInCarSheetData } from '../../../redux/StudentsSlice/InCarSheetSlice/AddMultiLessonInCarSheetSlice';

const Signatures = ({ page, setPage, selectedTopics, roadTestTopics, selectedLessonId, isManeuverSelection, maneuverNotes, roadTestOverallGrade, roadTestNotes }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const [instructorSignature, setInstructorSignature] = useState([]);
    const [studentSignature, setStudentSignature] = useState([]);

    const { loading, success, error, errorMessage } = useSelector((state)=> state.Students.InCarSheet.addLessonInCarSheet);
    const { 
        loading: addMultiLessonSheetLoading,
        success: addMultiLessonSheetSuccess, 
        error: addMultiLessonSheetError, 
        errorMessage: addMultiLessonSheetErrorMessage 
    } = useSelector((state)=> state.Students.InCarSheet.addMultiLessonInCarSheet);
    // handles ondrop function of dropzone
    const handleDropChange = (acceptedFiles, set) => {
        set(
            acceptedFiles.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
            )
        );
    };

    const handleInCarSheet = (e) => { 
        e.preventDefault();
        const topics = selectedTopics?.map((topic) => {
            return {
                maneuverId: topic._id,
                maneuverName: topic.name,
                grade: topic.previousGrade
            }
        });
        if(isManeuverSelection?.isRoadTest) {
            dispatch(AddLessonInCarSheetThunk({
                topics: roadTestTopics,
                notes: roadTestNotes,
                simulatedRoadTest: true,
                studentSign: "",
                instructorSign: "",
                overallGrade: roadTestOverallGrade,
                lessonId: selectedLessonId,
                studentId
            }));
        } 
        if(isManeuverSelection?.isTopic){
            dispatch(AddLessonInCarSheetThunk({
                topics: topics,
                notes: maneuverNotes,
                simulatedRoadTest: false,
                studentSign: "",
                instructorSign: "",
                lessonId: selectedLessonId,
                studentId
            }));
        }
        if(isManeuverSelection?.isBoth){
            dispatch(AddMultiLessonInCarSheetThunk({
                roadTestTopics, 
                maneuverTopics: topics,
                roadTestNotes: roadTestNotes,
                maneuverNotes: maneuverNotes,
                simulatedRoadTest: true,
                studentSign: "",
                instructorSign: "",
                overallGrade: roadTestOverallGrade,
                lessonId: selectedLessonId,
                studentId
            }))
        }
    }

    if(success){
        toast.success("In-Car sheet has been added successfully.");
        setPage({ signature: false, view: true });
        dispatch(ResetAddLessonInCarSheetData());
    }

    if(addMultiLessonSheetSuccess){
        toast.success("In-Car Sheet has been added successfully.");
        dispatch(ResetAddMultiLessonInCarSheetData());
        setPage({signature: false, view: true});
    }

    if(addMultiLessonSheetError){
        toast.error(addMultiLessonSheetErrorMessage);
        dispatch(ResetAddMultiLessonInCarSheetData());
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetAddLessonInCarSheetData());
    }

    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> setPage({signature: false, showSelectedTopic: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Col sm="12">
                <label className="form-label">Instructor's Signature</label>
                <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setInstructorSignature)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                        <input {...getInputProps()} />
                        {instructorSignature.length === 0 && (
                            <div className="dz-message">
                                <span className="dz-message-text">Drag and drop file</span>
                                <span className="dz-message-or">or</span>
                                <Button color="primary">SELECT</Button>
                            </div>
                        )}
                        {instructorSignature.map((file) => (
                            <div
                                key={file.name}
                                className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                                <div className="dz-image">
                                    <img src={file.preview} alt="preview" />
                                </div>
                            </div>
                        ))}
                        </div>
                    </section>
                    )}
                </Dropzone>
            </Col>
            <Col sm="12">
                <label className="form-label">Student Signature</label>
                <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setStudentSignature)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className="dropzone upload-zone dz-clickable">
                        <input {...getInputProps()} />
                        {studentSignature.length === 0 && (
                            <div className="dz-message">
                            <span className="dz-message-text">Drag and drop file</span>
                            <span className="dz-message-or">or</span>
                            <Button color="primary">SELECT</Button>
                            </div>
                        )}
                        {studentSignature.map((file) => (
                            <div
                            key={file.name}
                            className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                            <div className="dz-image">
                                <img src={file.preview} alt="preview" />
                            </div>
                            </div>
                        ))}
                        </div>
                    </section>
                    )}
                </Dropzone>
            </Col>
            <Col sm="12">
            <div className='w-100 d-flex justify-content-center mt-3'>
                        <Button disabled={loading || addMultiLessonSheetLoading} className='' onClick={handleInCarSheet}>
                            <span>{loading || addMultiLessonSheetLoading ? <Spinner size="sm" /> : "Save & Finish"} </span>
                        </Button>
                    </div>

            </Col>

        </React.Fragment>
    )
}

export default Signatures;