import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../../utils/ApiEndpoints";

// Remove In-Car Sheet 
const GetLessonSheetDetailThunk = createAsyncThunk(
    "GetLessonSheetDetail/GetLessonSheetDetailThunk",
    async ({ lessonSheetId }, { rejectWithValue }) => {
        try {
        const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.inCarSheet.GetLessonSheetDetail}?lessonSheetId=${lessonSheetId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
        console.log('response', response)
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const GetLessonSheetDetailsSlice = createSlice({
    name: "GetLessonSheetDetails",
    initialState: {
        lessonSheetDetailData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetGetLessonSheetDetailData: (state, action) => {
        state.lessonSheetDetailData = {};
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetLessonSheetDetailThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [GetLessonSheetDetailThunk.fulfilled]: (state, action) => {
        console.log('action.payload.data', action.payload);
        state.lessonSheetDetailData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [GetLessonSheetDetailThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default GetLessonSheetDetailsSlice.reducer;

export const { ResetGetLessonSheetDetailData } = GetLessonSheetDetailsSlice.actions;

export { GetLessonSheetDetailThunk };
