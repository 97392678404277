import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button, Card, Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../components/Component";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { GetStudentNotesThunk } from "../../redux/StudentsSlice/GetStudentNotesSlice";
import AddStudentNotesModal from "./AddStudentNotesModal";
import moment from "moment";

const StudentNotes = () => {
    const { studentId } = useParams();
    const dispatch = useDispatch();

    const { loading, error, studentNotesData } = useSelector((state) => state.Students.getStudentNotes);







    const [modal, setModal] = useState({
        add: false
    })
    useEffect(() => {
        dispatch(GetStudentNotesThunk({ studentId: studentId }));
    }, [dispatch, studentId]);

    return (
        <React.Fragment>
        <Head title="Student Notes - Admin Portal"></Head>
        {/* {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
            </div>
        ) : error ? (
            <span>No data Found</span>
        ) : ( */}
            <Content>
            <Block>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                        <BlockTitle page>Student Notes</BlockTitle>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        <Button color="primary" onClick={() => setModal({ add: true })}>
                            <Icon name="plus" />
                            <span>Add Notes</span>
                        </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Card className="card-bordered card-stretch">
                <div className="card-inner-group">
                    {/* <div className="card-inner">
                        <div className="card-title-group">
                            <div className="card-title">
                                <h5 className="title">Student Notes</h5>
                            </div>
                        </div>
                    </div> */}
                    <div className="card-inner p-0">
                    <table className="table table-tranx">
                        <thead>
                        <tr className="tb-tnx-head">
                            <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                                <span>Student Notes</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                                <span className="d-none d-md-block">
                                <span>Date & Time</span>
                                </span>
                            </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                            </div>
                        ) : error ? (
                            <span>No data Found</span>
                        ) : (
                            studentNotesData?.notes?.map((notesDetail, index) => {
                                console.log('notesDetail', notesDetail)
                                return (
                                <tr className="tb-tnx-item">
                                    <td className="tb-tnx-info">
                                    <div className="tb-tnx-desc">
                                        <span className="">{notesDetail.note}</span>
                                    </div>
                                    <div className="tb-tnx-date">
                                        <span className="">{moment(notesDetail.timeStamp).format("DD-MM-YYYY,  h:mm") }</span>
                                    </div>
                                    </td>
                                </tr>
                                );
                            })
                            )}
                        </tbody>
                    </table>
                    </div>
                </div>
                </Card>
            </Block>
            </Content>
        {/* )} */}

        {modal.add && <AddStudentNotesModal modal={modal} setModal={()=> setModal({ add: false })} />}
        </React.Fragment>
    );
};

export default StudentNotes;
