import React from 'react'
import { Button, Col, Form, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { ResetUpdateStudentRefundsData, UpdateStudentRefundsThunk } from '../../../redux/StudentsSlice/UpdateStudentRefundStatusSlice';
import { GetStudentRefundsThunk } from '../../../redux/StudentsSlice/GetStudentRefundsSlice';

const ChangeStatusModal = ({modal, setModal, selectedRefundData}) => {
    console.log('selectedRefundData', selectedRefundData)
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const { loading, success, error, errorMessage} = useSelector((state)=> state.Students.updateStudentRefund);
    const handleChangeStatus = (e) => {
        e.preventDefault();
        dispatch(UpdateStudentRefundsThunk({studentId, refundId: selectedRefundData?._id}))
    }

    if(success){
        toast.success("Status has been changed successfully.");
        dispatch(ResetUpdateStudentRefundsData());
        dispatch(GetStudentRefundsThunk({ studentId }));
        setModal();
    }
    if(error){
        toast.error(errorMessage);
        dispatch(ResetUpdateStudentRefundsData());
        setModal();
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={modal.changeStatus}
                toggle={() => setModal({ changeStatus: false })}
                className="modal-dialog-centered"
                size="md"
            >
                    <ModalBody>
                    <div
                        onClick={(ev) => {
                        ev.preventDefault();

                        setModal({ changeStatus: false });
                        }}
                        className="close cursor_pointer"
                    >
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h4 className="title">Change Status</h4>
                        <div className="mt-4">
                        <Form className="row gy-4 mt-4 mb-5">
                            <Col md="12">
                            <div>
                                <span>Are you sure you want to change status?</span>
                            </div>
                            </Col>
                            <Col size="12">
                            <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                <Button disabled={loading} onClick={(e) => handleChangeStatus(e)} color="primary" size="md" type="submit">
                                    {loading ? <Spinner /> : "Change Status"}
                                </Button>
                                </li>
                                <li>
                                <div
                                    onClick={(ev) => {
                                    ev.preventDefault();
                                        setModal({ changeStatus: false });
                                    }}
                                    className="link link-light cursor_pointer"
                                >
                                    Cancel
                                </div>
                                </li>
                            </ul>
                            </Col>
                        </Form>
                        </div>
                    </div>
                    </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default ChangeStatusModal;
