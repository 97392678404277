import * as Yup from "yup";

export const AddStudentValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is a required field"),
  lastName: Yup.string().required("Last Name is a required field"),
  phoneNumber: Yup.number()
    .max(9999999999, "Phone Number must have 10 digits")
    .min(1000000000, "Phone Number must have 10 digits")
    .required("Phone Number must have 10 digits"),
  email: Yup.string().email().required("Email is a required field"),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must have at least 8 characters with at least one capital letter, one number, and a special character"
    )
    .required(),
  postalCode: Yup.string().required(),
});

export const AddStudentInCarReportValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is a required field"),
  address: Yup.string().required("Address is a required field"),
  driversLicenseNumber: Yup.string().required("Driver Licence is a required field"),
});

export const AddStudentNotesValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Notes is a required field"),
});

export const AddStudentRoadTestInCarSheetValidationSchema = Yup.object().shape({
  note: Yup.string().required("Notes is a required field"),
});

export const EditInstructorProfileValidationSchema = Yup.object().shape({
  fullName: Yup.string(),
  phoneNumber: Yup.number(),
  email: Yup.string().email(),
  status: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  address: Yup.string(),
});

export const ChangePasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must have at least 8 characters with at least one capital letter, one number, and a special character"
    )
    .required(),
  confirmPassword: Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, "confirm Password is invalid")
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm Password is a required field"),
});
