import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ChangeLessonTypeThunk = createAsyncThunk(
"ChangeLessonType/ChangeLessonTypeThunk",
async ({ lessonId }, { rejectWithValue }) => {
    try {
    const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.ChangeLessonType}?lessonId=${lessonId}`,
        { },
        {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
    );
    return response.data;
    } catch (error) {
    console.log("error", error);
    return rejectWithValue(error.response.data);
    }
}
);

const ChangeLessonTypeSlice = createSlice({
  name: "ChangeLessonType",
  initialState: {
    ChangeLessonTypeData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetChangeLessonTypeData: (state, action) => {
      state.ChangeLessonTypeData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ChangeLessonTypeThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ChangeLessonTypeThunk.fulfilled]: (state, action) => {
      state.ChangeLessonTypeData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ChangeLessonTypeThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ChangeLessonTypeSlice.reducer;

export const { ResetChangeLessonTypeData } = ChangeLessonTypeSlice.actions;

export { ChangeLessonTypeThunk };
