import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const ViewAllGeoFencingThunk = createAsyncThunk(
    "GeoFencing/ViewAllGeoFencingThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.GeoFencing.ViewAllGeoFencing, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
            const errorMessage = error?.response?.data?.message || "Something went wrong";
            return rejectWithValue(errorMessage);
        }
    }
);

const ViewAllGeoFencingSlice = createSlice({
    name: "GeoFencing",
    initialState: {
        viewAllgeoFencingData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetViewAllGeoFencingData: (state, action) => {
            state.viewAllgeoFencingData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [ViewAllGeoFencingThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [ViewAllGeoFencingThunk.fulfilled]: (state, action) => {
            state.viewAllgeoFencingData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [ViewAllGeoFencingThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.viewAllgeoFencingData = [];
        },
    },
});

export default ViewAllGeoFencingSlice.reducer;

export const { ResetViewAllGeoFencingData } = ViewAllGeoFencingSlice.actions;

export { ViewAllGeoFencingThunk };
