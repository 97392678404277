import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, Modal, ModalBody, DropdownItem, ModalHeader, } from "reactstrap";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Col, Row, } from "../../components/Component";
import { saleOrders } from "../pre-built/orders/OrderData";
import { useDispatch } from "react-redux";
import { GetInstructorLessonsThunk } from "../../redux/InstructorSlice/GetInstructorLessons";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "reactstrap/es";
import { GetLessonDetailThunk } from "../../redux/LessonsSlice/GetLessonDetailSlice";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router";

const InstructorLesson = () => {
  const { instructorId } = useParams();

  const dispatch = useDispatch();

  const { loading, success, error, GetInstructorLessonsData } = useSelector(
    (state) => state.instructors.getInstructorLessons
  );

  const {
    loading: getLessonDetailLoading,
    error: getLessonDetailError,
    lessondetailData,
  } = useSelector((state) => state.lessons.getLessonDetail);

  const [onSearch, setonSearch] = useState(true);
  const [lessonData, setLessonData] = useState({});
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    add: false,
    showLesson: false,
  });

  // ********************************** Search and Pagination start ***********************************//

  const instructorLessonsPerPage = 10;
  const [allInstructorLessons, setAllInstructorLessons] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const pagesVisited = pageNumber * instructorLessonsPerPage;

  const pageCount = Math.ceil(
    allInstructorLessons?.filter((instructorLesson) => {
      if (onSearchText === "") {
        return instructorLesson;
      } else if (
        instructorLesson.studentName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.day.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.status.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.dateTime.split("T")[0].toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorLesson;
      }
      return false;
    }).length / instructorLessonsPerPage
  );

  const displayInstructorLessons = allInstructorLessons
    ?.filter((instructorLesson) => {
      if (onSearchText === "") {
        return instructorLesson;
      } else if (
        instructorLesson.studentName.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.day.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.status.toLowerCase().includes(onSearchText.toLowerCase()) ||
        instructorLesson.dateTime.split("T")[0].toLowerCase().includes(onSearchText.toLowerCase())
      ) {
        return instructorLesson;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + instructorLessonsPerPage);

  useEffect(() => {
    setAllInstructorLessons(GetInstructorLessonsData?.scheduled);
  }, [GetInstructorLessonsData]);


  useEffect(() => {
    setPageNumber(0);
  }, [onSearchText]);

  // ********************************** Pagination End ***********************************//

  useEffect(() => {
    dispatch(GetInstructorLessonsThunk({ instructorId: instructorId }));
  }, []);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = ({ selected }) => setPageNumber(selected);

  const handleLessonDetail = (id) => {
    dispatch(GetLessonDetailThunk({ lessonId: id }));
  };

  return (
    <React.Fragment>
      <Head title="Instructor's Lessons"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Lessons</BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {GetInstructorLessonsData?.scheduled?.length} lessons.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Card className="card-bordered card-stretch">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                <Spinner />
              </div>
            ) : (
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">Instructor's lessons</h5>
                    </div>
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                      </ul>
                    </div>
                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                      <div className="search-content">
                        <Button className="search-back btn-icon toggle-search" onClick={() => { setSearchText(""); toggle(); }}>
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="Search"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0 ">
                  <table className="table table-tranx mr-2">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id">
                          <span className="">#</span>
                        </th>
                        <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Name</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="d-none d-md-block">
                              <span>Day</span>
                              <span>Date</span>
                            </span>
                          </span>
                        </th>
                        {/* <th className="tb-tnx-info">
                            {" "}
                            <span className="d-md-block">Package</span>
                          </th> */}
                        <th className="tb-tnx-amount is-alt">
                          <span className="tb-tnx-total">Time</span>
                          <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                        </th>
                        <th className="tb-tnx-action">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    {error ? (
                      <div>
                        <span className="text-silent">No lessons found</span>
                      </div>
                    ) : (
                      <tbody>
                        {displayInstructorLessons?.length > 0
                          ? displayInstructorLessons?.map((scheduledLessons, index) => {
                            return (
                              <tr key={scheduledLessons._id} className="tb-tnx-item" style={{backgroundColor: `${scheduledLessons?.homePickup ? "#7460FB20": "#36A0CD15"}`}}>
                                <td className="tb-tnx-id">
                                  <a
                                    href="#ref"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <span>{pageNumber * instructorLessonsPerPage + index + 1}</span>
                                  </a>
                                </td>
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="title">{scheduledLessons.studentName}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">{scheduledLessons.day}</span>
                                    <span className="date">
                                      {scheduledLessons?.dateTime.split("T")[0]}
                                      {/* {moment.utc(scheduledLessons?.dateTime).format("hh:mm A")} */}
                                    </span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-total">
                                    <span className="amount">
                                      {moment.utc(scheduledLessons?.dateTime).format("hh:mm A")}
                                    </span>
                                  </div>
                                  <div className="tb-tnx-status">
                                    <span
                                      className={`badge badge-dot badge-${scheduledLessons.status === "Completed"
                                        ? "success"
                                        : scheduledLessons.status === "Scheduled"
                                          ? "warning"
                                          : "danger"
                                        }`}
                                    >
                                      {scheduledLessons.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <ul className="link-list-plain">
                                        <li>
                                          <DropdownItem onClick={(ev) => { setLessonData(scheduledLessons); handleLessonDetail(scheduledLessons._id); setModal({ showLesson: true }); }}>
                                            View Details
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="card-inner">
                  {!error && (
                    <ReactPaginate
                      breakLabel="..."
                      breakClassName="pagination-btns"
                      onPageChange={(number) => paginate(number)}
                      // onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel="<<  Prev "
                      renderOnZeroPageCount={null}
                      nextLabel="Next >>"
                      previousClassName="pagination-next"
                      nextClassName="pagination-next"
                      activeClassName="pagination-active"
                      pageClassName="pagination mt-0"
                      pageLinkClassName="pagination-btns"
                      containerClassName="pagination justify-content-start"
                    />
                  )}
                  {/* {currentItems.length > 0 ? (
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )} */}
                </div>
              </div>
            )}
          </Card>
        </Block>
      </Content>

      <Modal isOpen={modal.showLesson} toggle={() => setModal({ showLesson: false })} className="modal-md">
        <ModalHeader className="fc-event-info" toggle={() => setModal({ showLesson: false })}>
          {lessonData?.studentName && lessonData.studentName}
        </ModalHeader>
        <ModalBody>
          {getLessonDetailLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <Spinner />
            </div>
          ) : getLessonDetailError || Object.keys(lessondetailData).length === 0 ? (
            <p>No detail Found</p>
          ) : (
            <Row className="gy-3 py-1">
              <Col sm="6">
                <h6 className="overline-title">Start Time</h6>
                <p id="preview-event-start">
                  {lessondetailData?.lesson && moment.utc(lessondetailData?.lesson.dateTime).format("hh:mm A")}
                </p>
              </Col>
              <Col sm="6" id="preview-event-end-check">
                <h6 className="overline-title">End Time</h6>
                <p id="preview-event-end">
                  {lessondetailData?.lesson &&
                    moment.utc(lessondetailData?.lesson.dateTime).add(1, "hour").format("hh:mm A")}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">City</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.city}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Home Pickup Location</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson?.homePickup}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Status</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.status}</p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Type</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson.isRoadTest ? "Road Test" : "Regular"}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Purchase Date</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson.purchaseDate.split("T")[0]}
                </p>
              </Col>
              <Col sm="6" id="preview-event-description-check">
                <h6 className="overline-title">Duration</h6>
                <p id="preview-event-description">{lessondetailData?.lesson && lessondetailData?.lesson.duration}</p>
              </Col>

              <Col sm="12" id="preview-event-description-check">
                <h6 className="overline-title">Pickup Location</h6>
                <p id="preview-event-description">
                  {lessondetailData?.lesson && lessondetailData?.lesson?.pickupLocation}
                </p>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default InstructorLesson;
