import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ImageUploaderThunk = createAsyncThunk(
  "ImageUploader/ImageUploaderThunk",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${ApiEndpoints.UploadImages}/pickupLocations`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const ImageUploaderSlice = createSlice({
  name: "ImageUploader",
  initialState: {
    ImageUploaderData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetImageUploader: (state, action) => {
      state.ImageUploaderData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ImageUploaderThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ImageUploaderThunk.fulfilled]: (state, action) => {
      state.ImageUploaderData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ImageUploaderThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ImageUploaderSlice.reducer;

export const { ResetImageUploader } = ImageUploaderSlice.actions;

export { ImageUploaderThunk };
