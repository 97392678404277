import React, { useEffect } from 'react';
import { useState } from 'react';
import Head from '../../../layout/head/Head';
import AddInCarSheet from './AddInCarSheet';
import EditInCarSheet from './EditInCarSheet';
import RoadTestLessonEvaluationModal from './RoadTestLessonEvaluationModal';
import ShowAllInCarLesson from './ShowAllInCarLesson';
import ShowSelectedTopic from './ShowSelectedTopic';
import Signatures from './Signatures';
import StudentInCarReport from './StudentInCarReport';
import SimulatedTestInCarSheet from './SimulatedTestInCarSheet';
import { useDispatch } from 'react-redux';
import { ResetGetAllInCarSheetManeuversData } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetManeuversSlice';
import ShowRoadTestDetailModal from './ShowRoadTestDetailModal';
import ShowInCarReport from './ShowInCarReport';

document.title = "Student In-Car Sheet | Admin Portal";

const InCarsheet = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState({
        view: true,
        inCarReport: false,
        add: false,
        showSelectedTopic:false,
        showInCarReport: false,
        signature: false,
        edit: false,
        simulatedTestInCarSheet: false
    });
    const [modal, setModal ]= useState({
        addRoadTestEvaluation: false,
        view: false
    });
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [isMultiInCarSheet, setIsMultiInCarSheet ] = useState(false);
    const [selectedLessonId, setSelectedLessonId ] = useState();
    const [roadTestTopics, setRoadTestTopics ] = useState([]);
    const [roadTestNotes, setRoadTestNotes ] = useState("");
    const [maneuverNotes, setManeuverNotes ] = useState("");
    const [selectedLessonSheetId, setSelectedLessonSheetId ] = useState(false);
    const [roadTestOverallGrade, setRoadTestOverallGrade] = useState();
    const [isManeuverSelection, setIsManeuverSelection ] = useState({
        isTopic: false,
        isRoadTest: false,
        isBoth: false
    })

    useEffect(()=>{
        dispatch(ResetGetAllInCarSheetManeuversData());
    },[]);

    return (
        <React.Fragment>
            <Head title="Student In-Car Sheet | Admin Portal" />
            {page.view && <ShowAllInCarLesson setManeuverNotes={setManeuverNotes} setRoadTestNotes={setRoadTestNotes} setRoadTestTopics={setRoadTestTopics} setSelectedTopics={setSelectedTopics} setPage={setPage} setSelectedLessonId={setSelectedLessonId} setModal={setModal} setSelectedLessonSheetId={setSelectedLessonSheetId} />}
            {page.inCarReport && <StudentInCarReport page={page} setPage={setPage} /> }
            {page.add && <AddInCarSheet page={page} setPage={setPage} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} setIsMultiInCarSheet={setIsMultiInCarSheet} isManeuverSelection={isManeuverSelection} setIsManeuverSelection={setIsManeuverSelection} />}
            {page.showSelectedTopic && <ShowSelectedTopic page={page} setPage={setPage} selectedTopics={selectedTopics} setSelectedTopics={setSelectedTopics} maneuverNotes={maneuverNotes} setManeuverNotes={setManeuverNotes} isManeuverSelection={isManeuverSelection} />}
            {page.edit && <EditInCarSheet setPage={setPage} selectedLessonSheetId={selectedLessonSheetId} />}
            {page.simulatedTestInCarSheet && <SimulatedTestInCarSheet page={page} setPage={setPage} roadTestTopics={roadTestTopics} setRoadTestTopics={setRoadTestTopics} roadTestNotes={roadTestNotes} setRoadTestNotes={setRoadTestNotes} roadTestOverallGrade={roadTestOverallGrade} setRoadTestOverallGrade={setRoadTestOverallGrade} isManeuverSelection={isManeuverSelection} />}
            {page.signature && <Signatures page={page} setPage={setPage} selectedTopics={selectedTopics} roadTestTopics={roadTestTopics} selectedLessonId={selectedLessonId} isManeuverSelection={isManeuverSelection} roadTestNotes={roadTestNotes} roadTestOverallGrade={roadTestOverallGrade}  maneuverNotes={maneuverNotes} setManeuverNotes={setManeuverNotes} /> }
            {page.showInCarReport && <ShowInCarReport page={page} setPage={setPage} /> }

            {modal.addRoadTestEvaluation && <RoadTestLessonEvaluationModal selectedLessonId={selectedLessonId} modal={modal} setModal={()=> setModal({addRoadTestEvaluation: false})} />}
            {modal.view && <ShowRoadTestDetailModal modal={modal} setModal={()=> setModal({addRoadTestEvaluation: false})} lessonId={selectedLessonId} selectedLessonSheetId={selectedLessonSheetId} />}

        </React.Fragment>
    )
};

export default InCarsheet;