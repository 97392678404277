import { combineReducers } from "@reduxjs/toolkit";
import AddSeasonalPromotionSlice from "./AddSeasonalPromotionSlice";
import DeleteSeasonalPromotionSlice from "./DeleteSeasonalPromotionSlice";
import GetAllPromotionsSlice from "./GetAllPromotionsSlice";
import UpdateReferralPromotionStatusSlice from "./UpdateReferralPromotionStatusSlice";
import UpdateSeasonalPromotionStatusSlice from "./UpdateSeasonalPromotionStatusSlice";

export const PromotionsReducers = combineReducers({
  allPromotions: GetAllPromotionsSlice,
  addSeasonalPromotion: AddSeasonalPromotionSlice,
  updateSeasonalPromotionStatus: UpdateSeasonalPromotionStatusSlice,
  updateReferralPromotionStatus: UpdateReferralPromotionStatusSlice,
  deleteSeasonalPromotion: DeleteSeasonalPromotionSlice,
});
