import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap/es";
import { useParams } from "react-router";
import moment from "moment-timezone";
import { Toaster } from "react-hot-toast";

import Head from "../../../layout/head/Head";
import { GetInstructorScheduleThunk } from "../../../redux/InstructorSlice/GetInstructorScheduleSlice";
import ShowInstructorWeeklySlots from "./ShowInstructorWeeklySlots";
import ShowInstructorDaySlots from "./ShowInstructorDaySlots";
import AddInstructorSlot from "./AddInstructorSlot";
import UpdateInstructorSlot from "./UpdateInstructorSlot";
const InstructorSchedule = () => { 
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  // ***************************************** All API's SELECTOR ********************************//
  const { loading: GetInstructorScheduleLoading, instructorScheduleData } = useSelector(
    (state) => state.instructors.getInstructorSchedule
  );

  // ***************************************** All Hooks ********************************//
  const [modal, setModal] = useState({
    showCalender: false,
    showWeeklySlots: true,
    updateSlot: false,
    showDaySlots: false,
    addSlot: false,
    addEvent: false
  });
  const [selectedDay, setSelectedDay] = useState("");
  const [dayData, setDayData] = useState({});
  const [oldTimeSlot, setOldTimeSlot] = useState({});
  const [oldLocations, setOldLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [scheduleTime, setScheduleTime] = useState({
    startTime: moment().startOf("hour").toDate(),
    endTime: moment().add(1, "hour").startOf("hour").toDate(),
  });

  useEffect(() => {
    dispatch(GetInstructorScheduleThunk({ instructorId }));
  }, [dispatch, instructorId]);

  const handleDaySchedule = ({ dayTimeSlot, day }) => {
    const temp = [];
    var breakerIndex = 0;
    for (let index = 0; index < dayTimeSlot.length; index++) {
      if (index + 1 !== dayTimeSlot.length) {
        var ms = moment(dayTimeSlot[index].endTime).diff(moment(dayTimeSlot[index + 1].startTime));
        if (Number(ms) !== 0) {
          const slotLocations = instructorScheduleData?.locations?.filter(location => dayTimeSlot[breakerIndex]?.locations?.includes(location._id))
          temp.push({ startTime: dayTimeSlot[breakerIndex].startTime, endTime: dayTimeSlot[index].endTime,locations: slotLocations });
          breakerIndex = index + 1;
        }
      } else {
        const slotLocations = instructorScheduleData?.locations?.filter(location => dayTimeSlot[breakerIndex]?.locations?.includes(location._id))
        temp.push({ startTime: dayTimeSlot[breakerIndex].startTime, endTime: dayTimeSlot[index].endTime, locations: slotLocations});
      }
    }

    setDayData({ dayTimeSlot: temp, day });
    setOldLocations([])
    setModal({ showWeeklySlots: false, showDaySlots: true });
  };

  const handleSpecificTimeSlot = ({ timeSlot, location }) => {
    setSelectedLocations([]);
    setOldLocations([]);
    instructorScheduleData?.locations.map(( origLoc ) => {
        const pushObj = {
          _id: origLoc._id,
          name: origLoc.name,
          isChecked: true
        }
        let bool = false;
        location.map(( checkedLoc ) => {
          if(origLoc._id === checkedLoc._id)
            bool = true;
        });

        if(bool) {
          pushObj.isChecked = true
          setOldLocations(prevOldLocations => [...prevOldLocations, pushObj]);
          setSelectedLocations(prevSelectedLocations => [...prevSelectedLocations, pushObj._id]);
          bool = false
        } else {
          pushObj.isChecked = false
          setOldLocations(prevOldLocations => [...prevOldLocations, pushObj])
          bool = false
        }
        
      }
    )

    const startTimeString = timeSlot?.startTime;
    const endTimeString = timeSlot?.endTime;
    
    // Parse the date strings and interpret them as UTC
    const startTime = moment.tz(startTimeString, 'UTC').add(5, 'hours');
    const endTime = moment.tz(endTimeString, 'UTC').add(5, 'hours');
    
    // Now you have startTime and endTime adjusted for the specified timezone
    
    // Convert the Moment.js objects back to JavaScript Date objects (if needed)
    const startTimeDate = startTime.toDate();
    const endTimeDate = endTime.toDate();
    
    console.log('startTimeDate =====>>>>>', startTimeDate)
    // Now you can use the adjusted date objects with React-datepicker
    setScheduleTime({ startTime: startTimeDate, endTime: endTimeDate });
    setOldTimeSlot({ ...timeSlot });
    setModal({ showDaySlots: false, updateSlot: true });
  };

  return (
    <React.Fragment>
      <Toaster />
      { GetInstructorScheduleLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          <Head title="Instructor Schedule" />
          {modal.showWeeklySlots && 
            <ShowInstructorWeeklySlots 
              modal={modal} 
              setModal={setModal} 
              setSelectedDay={setSelectedDay} 
              handleDaySchedule={handleDaySchedule} 
            />
          }
          {modal.showDaySlots && 
            <ShowInstructorDaySlots 
            modal={modal} 
            setModal={setModal} 
            handleSpecificTimeSlot={handleSpecificTimeSlot} 
            dayData={dayData} 
            /> 
          }
          {modal.addSlot && 
            <AddInstructorSlot 
              modal={modal} 
              setModal={setModal} 
              GetInstructorScheduleLoading={GetInstructorScheduleLoading} 
              oldLocations={oldLocations} 
              setOldLocations={setOldLocations} 
              selectedLocations={selectedLocations} 
              setSelectedLocations={setSelectedLocations} 
              selectedDay={selectedDay} 
            /> 
          }
          {modal.updateSlot && 
            <UpdateInstructorSlot
              modal={modal}
              setModal={()=> setModal({updateSlot: false, showWeeklySlots: true})}
              goBack={()=> setModal({showDaySlots: true, updateSlot: false})}
              GetInstructorScheduleLoading={GetInstructorScheduleLoading}
              scheduleTime={scheduleTime}
              setScheduleTime={setScheduleTime}
              oldLocations={oldLocations}
              setOldLocations={setOldLocations}
              setSelectedLocations={setSelectedLocations}
              oldTimeSlot={oldTimeSlot}
              selectedDay={selectedDay}
              selectedLocations={selectedLocations}
            />
          }
        </>
      )}
    </React.Fragment>
  );
};

export default InstructorSchedule;