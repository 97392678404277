import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Delete student
const DeleteStudentThunk = createAsyncThunk(
  "DeleteStudent/DeleteStudentThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${ApiEndpoints.StudentsEndPoint.DeleteStudent}?studentId=${studentId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const DeleteStudentsSlice = createSlice({
  name: "DeleteStudents",
  initialState: {
    DeleteStudentData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDeleteStudentData: (state, action) => {
      state.DeleteStudentData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [DeleteStudentThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DeleteStudentThunk.fulfilled]: (state, action) => {
      state.DeleteStudentData = [...state.DeleteStudentData, action.payload.data];
      state.success = true;
      state.loading = false;
    },
    [DeleteStudentThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DeleteStudentsSlice.reducer;

export const { ResetDeleteStudentData } = DeleteStudentsSlice.actions;

export { DeleteStudentThunk };
