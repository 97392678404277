import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Modal, ModalBody, FormGroup, Spinner } from "reactstrap";
import { Block, Icon, Row, Col, Button, RSelect } from "../../components/Component";
import { transmissionOptions } from "../pre-built/user-manage/UserData";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik, Field } from "formik";
import { useParams } from "react-router";
import { GetInstructorVehicleDetailThunk } from "../../redux/InstructorSlice/GetInstructorVehicleDetailSlice";
import {
  EditInstructorProfileThunk,
  ResetEditInstructorProfile,
} from "../../redux/InstructorSlice/EditInstructorProfileSlice";
import toast from "react-hot-toast";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import axios from "axios";
import ReactImageUploading from "react-images-uploading";

const InstructorVehicleDetails = () => {
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  const [LogoImageLoading, setLogoImageLoading] = useState(false);

  const { loading, instructorVehicleData } = useSelector((state) => state.instructors.getInstructorVehicleDetails);

  const {
    loading: EditInstructorProfileLoading,
    success: EditInstructorProfileSuccess,
    error: EditInstructorProfileError,
    errorMessage: EditInstructorProfileErrorMessage,
  } = useSelector((state) => state.instructors.editInstructorProfile);
  const [formData, setFormData] = useState({
    make: "",
    madel: "",
    color: "",
    transmission: "",
    year: "",
  });
  const [modal, setModal] = useState(false);

  // call instructor detail api through thunk function
  useEffect(() => {
    dispatch(GetInstructorVehicleDetailThunk({ instructorId: instructorId }));
  }, [dispatch, instructorId]);

  useEffect(() => {
    if (instructorVehicleData?.vehicleDetails) {
      setFormData({ ...instructorVehicleData?.vehicleDetails });
    }
  }, [instructorVehicleData]);

  // useEffect(() => {
  //   setProfileName(instructorData?.fullName);
  //   setProfileEmail(instructorData?.email);
  // }, [instructorData]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogoImageUploader = async (e) => {
    e.preventDefault();
    const formDt = new FormData();
    if (Number((e.target.files[0].size) / 1024) > 1000) {
      toast.error("Image size exceeding 1MB, please upload a smaller image");
    } else {
      setLogoImageLoading(true);
      formDt.append("image", e.target.files[0]);
      try {
        const response = await axios.post(`${ApiEndpoints.UploadImages}/vehicles?instructorId=${instructorId}`, formDt, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setFormData({ ...formData, image: response.data.imageName });
        setLogoImageLoading(false);
      } catch (error) {
        setLogoImageLoading(false);
      }
    }
  };

  const submitForm = () => {
    dispatch(EditInstructorProfileThunk({ vehicleDetails: formData, instructorId: instructorId }));
  };

  if (EditInstructorProfileSuccess) {
    toast.success("Vehicle detail has been updated successfully.");
    dispatch(ResetEditInstructorProfile());
    dispatch(GetInstructorVehicleDetailThunk({ instructorId: instructorId }));
    setModal(false);
  }

  if (EditInstructorProfileError) {
    toast.error(EditInstructorProfileErrorMessage);
    dispatch(ResetEditInstructorProfile());
    dispatch(GetInstructorVehicleDetailThunk({ instructorId: instructorId }));
    setModal(false);
  }

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <br />
      <br />
      <br />
      <Block>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
          </div>
        ) : (
          <>
            {Object.keys(instructorVehicleData).length === 0 ? (
              <p>No data Found</p>
            ) : (
              <>
                <div className="nk-data data-list">
                  <div className="data-head">
                    <h6 className="overline-title">Vehicle Details</h6>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Make</span>
                      <span className="data-value">{instructorVehicleData?.vehicleDetails?.make}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Model</span>
                      <span className="data-value">{instructorVehicleData.vehicleDetails?.model}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Color</span>
                      <span className="data-value text-soft">{instructorVehicleData?.vehicleDetails?.color}</span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Transmission</span>
                      <span className="data-value">{instructorVehicleData?.vehicleDetails?.transmission} </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                  <div className="data-item" onClick={() => setModal(true)}>
                    <div className="data-col">
                      <span className="data-label">Year</span>
                      <span className="data-value">{instructorVehicleData?.vehicleDetails?.year} </span>
                    </div>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        <Icon name="forward-ios"></Icon>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title mb-5">Update Vehicle Detail</h5>
            <div className="tab-content m-3">
              <div className="tab-pane active" id="personal">
                <Row className="gy-4">
                  <Formik>
                    {({ errors, touched }) => (
                      <Form>
                        <Row className="gy-4">
                          <Col md="3">
                            <FormGroup>
                              <ReactImageUploading
                                className="form-control"
                                multiple
                                maxNumber={10}
                                dataURLKey="data_url"
                              >
                                {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                  <div className="upload__image-wrapper">

                                    <div className="row image-item">
                                      <Row>
                                        <Col className="m-2" md="12">
                                          <img src={`${ApiEndpoints.GetImage}/vehicles/${formData?.image}`} style={{ borderRadius: "5%" }} alt="" width="120" height="100" />
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                )}
                              </ReactImageUploading>

                            </FormGroup>
                          </Col>


                          <Col md="9">
                            <FormGroup className="mt-3">
                              <label className="form-label">Profile Image</label>
                              <input
                                className="form-control"
                                style={{ cursor: "pointer" }}
                                // ref={register({ required: "This field is required" })}
                                type="file"
                                name="logo"
                                // defaultValue={formData?.instructorImage}
                                onChange={(e) => handleLogoImageUploader(e)}
                              />
                              {LogoImageLoading && <Spinner />}
                              {errors.logo && <span className="invalid">{errors.logo.message}</span>}
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="make">
                                Make
                              </label>
                              <Field
                                type="text"
                                id="make"
                                className="form-control"
                                name="make"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={instructorVehicleData?.vehicleDetails?.make}
                                placeholder="Enter Maker of a vehicle"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            {/* <FormGroup> */}
                            <label className="form-label" htmlFor="model">
                              Model
                            </label>
                            <Field
                              type="text"
                              id="model"
                              className="form-control"
                              name="model"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={instructorVehicleData?.vehicleDetails?.model}
                              placeholder="Enter Model "
                            />
                            {/* </FormGroup> */}
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="color">
                                Color
                              </label>
                              <Field
                                type="text"
                                id="color"
                                className="form-control"
                                name="color"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={instructorVehicleData?.vehicleDetails?.color}
                                placeholder="Enter color"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="transmission">
                                Transmission
                              </label>
                              <RSelect
                                type="text"
                                id="transmission"
                                // className="form-control"
                                name="transmission"
                                onChange={(e) => setFormData({ ...formData, transmission: e.value })}
                                // defaultValue={instructorVehicleData?.vehicleDetails?.transmission}
                                placeholder="Enter a transmission"
                                options={transmissionOptions}
                              // onChange={(e) => SetSelectedPickupPoint(e.value)}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label className="form-label" htmlFor="year">
                                Year
                              </label>
                              <Field
                                type="number"
                                id="year"
                                className="form-control"
                                name="year"
                                onChange={(e) => onInputChange(e)}
                                defaultValue={instructorVehicleData?.vehicleDetails?.year}
                                placeholder="Enter a year"
                              />
                            </FormGroup>
                          </Col>
                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  disabled={EditInstructorProfileLoading}
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    submitForm();
                                  }}
                                >
                                  {EditInstructorProfileLoading ? <Spinner /> : "Update Vehicle Details"}
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal(false);
                                  }}
                                  className="link link-light"
                                >
                                  Cancel
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default InstructorVehicleDetails;
