import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteInstructorThunk = createAsyncThunk(
    "DeleteInstructor/DeleteInstructorThunk",
    async ({ instructorId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${ApiEndpoints.InstructorsEndPoint.DeleteInstructor}?instructorId=${instructorId}`,
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
                }
            );
            return response.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const DeleteInstructorSlice = createSlice({
    name: "DeleteInstructor",
    initialState: {
        deleteInstructorData: {},
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetDeleteInstructorData: (state, action) => {
            state.deleteInstructorData = {};
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [DeleteInstructorThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [DeleteInstructorThunk.fulfilled]: (state, action) => {
            state.deleteInstructorData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [DeleteInstructorThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload;
            state.error = true;
            state.loading = false;
            state.deleteInstructorData = {};

        },
    },
});

export default DeleteInstructorSlice.reducer;

export const { ResetDeleteInstructorData } = DeleteInstructorSlice.actions;

export { DeleteInstructorThunk };
