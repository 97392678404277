import React, { useState } from 'react';
import { Button, Card, CardBody, CardText, Col, Input, Row, Spinner } from 'reactstrap';
import Head from '../../../layout/head/Head';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllInCarSheetManeuversThunk, ResetGetAllInCarSheetManeuversData } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetManeuversSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { Icon } from '../../../components/Component';
import { GetAllRoadTestManeuversThunk } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllRoadTestManeuversSlice';

const SimulatedTestInCarSheet = ({ page, setPage, roadTestTopics, setRoadTestTopics, roadTestOverallGrade, setRoadTestOverallGrade, roadTestNotes, setRoadTestNotes, isManeuverSelection }) => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const [isColorChange, setIsColorChange] = useState({
        firstMark: false,
        secondMark: false,
        thirdMark: false
    })
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const { loading, GetAllRoadTestManeuversData } = useSelector(( state ) => state.Students.InCarSheet.getAllRoadTestManeuvers)

    const handlerSimulatedRoadTestSheet = (selectedRoadTestTopic, grade) => { 
        if (selectedQuestions.includes(selectedRoadTestTopic._id)) {
            const updatedRoadTestTopicgrade = roadTestTopics.map(roadTestTopic => {
                if (roadTestTopic.maneuverId === selectedRoadTestTopic._id) {
                    return { ...roadTestTopic, grade: grade };
                }
                return roadTestTopic;
            });
            setRoadTestTopics(updatedRoadTestTopicgrade);
        } else {
            setSelectedQuestions([...selectedQuestions, selectedRoadTestTopic._id]);
            setRoadTestTopics([...roadTestTopics, {
                maneuverId: selectedRoadTestTopic._id,
                maneuverName: selectedRoadTestTopic.name,
                grade,
                heading: selectedRoadTestTopic.heading
            }]);
        }
        setIsColorChange({firstMark: !isColorChange.firstMark});
    };

    const hanldeBack = (e) => { 
        e.preventDefault();
        dispatch(ResetGetAllInCarSheetManeuversData());
        (isManeuverSelection.isBoth) && setPage({ simulatedTestInCarSheet: false, showSelectedTopic: true });
        (isManeuverSelection.isRoadTest) && setPage({ simulatedTestInCarSheet: false, add: true });
    }

    const handleSimulatedRoadTestTopics = (second) => { 
        setPage({simulatedTestInCarSheet: false, signature: true});
    }

    useEffect(()=>{
        dispatch(GetAllRoadTestManeuversThunk({ studentId, isRoadTest: true }))
    },[dispatch]);

    return (
        <React.Fragment>
            <Head title="Student In-Car Sheet | Admin Portal" />
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={hanldeBack}> 
                                <Icon name="back-arrow-fill"/>
                                Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
            : (
                <>
                    {GetAllRoadTestManeuversData?.maneuvers ?
                        Object.keys(GetAllRoadTestManeuversData?.maneuvers)?.map((category) => (
                            <div className='mb-3'>
                                <Card className=''>
                                    <CardBody>
                                        <h6 className='mb-4'>{category}</h6>
                                        {GetAllRoadTestManeuversData?.maneuvers[category]?.map((question) => {
                                            return (
                                                <CardText>
                                                    <div>{question?.name}</div>
                                                    <div className='mt-3'>
                                                        <div className='d-flex justify-content-start'>
                                                            <div className='selected-radio-button-main cursor_pointer' style={roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.maneuverId === question._id && roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.grade === 0 ? {color: "white", backgroundColor: "red"}: {color: "red"}} onClick={()=> handlerSimulatedRoadTestSheet(question, 0)}>
                                                                <div className='selected-radio-button' >
                                                                    xx
                                                                </div>
                                                            </div>
                                                            <div className='selected-radio-button-main cursor_pointer' style={roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.maneuverId === question._id && roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.grade === 1 ? {color: "white", backgroundColor: "orange"}: {color: "orange"}} onClick={()=> handlerSimulatedRoadTestSheet(question, 1)}>
                                                                <div className='selected-radio-button'>
                                                                    x
                                                                </div>
                                                            </div>
                                                            <div className='selected-radio-button-main cursor_pointer' style={roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.maneuverId === question._id && roadTestTopics.find((selectedManeuver) => selectedManeuver.maneuverId === question._id)?.grade === 2 ? {color: "white", backgroundColor: "green"}: {color: "green"}} onClick={()=> handlerSimulatedRoadTestSheet(question, 2)}>
                                                                <div className='selected-radio-button' >
                                                                    ok
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardText>
                                            )
                                        })}                        
                                    </CardBody>
                                </Card>
                            </div>
                    ))  
                    : 
                        <div>
                            <span>No Data Found</span>
                        </div>
                    }
                    <Row>
                        <Col md="6">
                            <label className="form-label">Overall Grade</label>
                            <Input
                                className='form-control'
                                value={roadTestOverallGrade}
                                type='number'
                                onChange={(e)=> setRoadTestOverallGrade(e.target.value)}
                                placeholder='Enter student grade out of 100'
                            />
                        </Col>
                        <Col md="12">
                            <label className="form-label">Notes</label>
                            <Input
                                
                                className='form-control'
                                type='textarea'
                                value={roadTestNotes}
                                onChange={(e)=> setRoadTestNotes(e.target.value)}
                                placeholder='Type here'
                            />
                        </Col>
                    </Row>
                        <div className='w-100 d-flex justify-content-center mt-3'>
                            <Button className='' onClick={handleSimulatedRoadTestTopics} >
                                Save & Proceed
                            </Button>
                        </div>

                </>
            )
        }
        </React.Fragment>
    )
}

export default SimulatedTestInCarSheet;