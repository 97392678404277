import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentInstructorsThunk = createAsyncThunk(
  "GetStudentInstructors/GetStudentInstructorsThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.StudentsEndPoint.GetStudentInstructors}?studentId=${studentId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const AssignStudentInstructorThunk = createAsyncThunk(
  "GetStudentInstructors/AssignStudentInstructorThunk",
  async ({ studentId, instructorId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.StudentsEndPoint.AssignNewStudentInstructor}?studentId=${studentId}&instructorId=${instructorId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentInstructorsSlice = createSlice({
  name: "GetStudentInstructors",
  initialState: {
    studentInstructorsData: [],
    success: false,
    assignInstructorSuccess: false,
    assignInstructorError: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentInstructorsData: (state, action) => {
      state.studentInstructorsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.assignInstructorSuccess = false;
      state.assignInstructorError = false;
      state.errorMessage = "";
    },
    ResetAssignStudentInstructorsData: (state, action) => {
      state.assignInstructorSuccess = false;
      state.assignInstructorError = false;
    },
  },
  extraReducers: {
    [GetStudentInstructorsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentInstructorsThunk.fulfilled]: (state, action) => {
      state.studentInstructorsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentInstructorsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
    [AssignStudentInstructorThunk.pending]: (state, action) => {
      state.loading = true;
      state.assignInstructorSuccess = false;
      state.assignInstructorError = false;
    },
    [AssignStudentInstructorThunk.fulfilled]: (state, action) => {
      state.studentInstructorsData = action.payload;
      state.assignInstructorSuccess = true;
      state.loading = false;
    },
    [AssignStudentInstructorThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.assignInstructorSuccess = false;
      state.assignInstructorError = true;
      state.loading = false;
    },
  },
});

export default GetStudentInstructorsSlice.reducer;

export const { ResetStudentInstructorsData, ResetAssignStudentInstructorsData } = GetStudentInstructorsSlice.actions;

export { GetStudentInstructorsThunk, AssignStudentInstructorThunk };
