import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetLessonDetailThunk = createAsyncThunk(
  "GetLessonDetail/GetLessonDetailThunk",
  async ({ lessonId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.LesssonsEndPoint.GetLessonDetail}?lessonId=${lessonId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetLessonDetailSlice = createSlice({
  name: "GetLessonDetail",
  initialState: {
    lessondetailData: {},
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetGetLessonDetailData: (state, action) => {
      state.lessondetailData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetLessonDetailThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetLessonDetailThunk.fulfilled]: (state, action) => {
      state.lessondetailData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetLessonDetailThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetLessonDetailSlice.reducer;

export const { ResetGetLessonDetailData } = GetLessonDetailSlice.actions;

export { GetLessonDetailThunk };
