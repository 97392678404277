import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorInvoiceDetailThunk = createAsyncThunk(
  "GetInstructorInvoiceDetail/GetInstructorInvoiceDetailThunk",
  async ({ invoiceId}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.InstructorsEndPoint.GetInstructorInvoiceDetail}?invoiceId=${invoiceId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetInstructorInvoiceDetailSlice = createSlice({
  name: "GetInstructorInvoiceDetail",
  initialState: {
    instructorInvoiceDetailData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInstructorInvoiceDetailData: (state, action) => {
      state.instructorInvoiceDetailData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetInstructorInvoiceDetailThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetInstructorInvoiceDetailThunk.fulfilled]: (state, action) => {
      state.instructorInvoiceDetailData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetInstructorInvoiceDetailThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetInstructorInvoiceDetailSlice.reducer;

export const { ResetInstructorInvoiceDetailData } = GetInstructorInvoiceDetailSlice.actions;

export { GetInstructorInvoiceDetailThunk };
