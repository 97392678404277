import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card, Col, Form, Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, } from "../../components/Component";
import { ResetUpdateStudentNotificationData, UpdateStudentNotificationThunk } from "../../redux/ConfigurationSlice/UpdateStudentnotificationSlice";
import { GetStudentNotificationThunk } from "../../redux/ConfigurationSlice/GetStudentNotificationSlice";
import { toast } from "react-hot-toast";

const Configuration = () => {
    const dispatch = useDispatch();

    const [modal, setModal] = useState({
        studentNotification: false
    })

    const { loading, getStudentNotificationData } = useSelector((state) => state.configuration.getStudentNotification)
    const { loading: UpdateNotificationLoading, success, error, errorMessage } = useSelector((state) => state.configuration.updateStudentNotification)

    useEffect(() => {
        dispatch(GetStudentNotificationThunk());
    }, [dispatch])

    const handleStudentNotification = (e) => {
        e.preventDefault();
        dispatch(UpdateStudentNotificationThunk({ isEnabled: !getStudentNotificationData.isEnabled }))
    }

    if (success) {
        toast.success("Updated Successfully.");
        dispatch(ResetUpdateStudentNotificationData());
        dispatch(GetStudentNotificationThunk());
        setModal({ studentNotification: false });
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetUpdateStudentNotificationData());
        dispatch(GetStudentNotificationThunk());
        setModal({ studentNotification: false });
    }

    return (
        <React.Fragment>
            <Head title="Configuration - Admin portal"></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Admin Configuration</BlockTitle>
                            <BlockDes className="text-soft">
                            </BlockDes>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <Block>
                    {loading ?
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                            <Spinner />
                        </div>
                        : (
                            <Card className="card-bordered card-stretch">
                                <div className="card-inner-group">
                                    <div className="card-inner p-0">
                                        <table className="table table-tranx">
                                            <tbody>
                                                <tr className="tb-tnx-item">
                                                    <td className="tb-tnx-info">
                                                        <div className="tb-tnx-desc">
                                                            <span className="title">Student Lesson Reminder Notification</span>
                                                        </div>
                                                    </td>
                                                    <td className="tb-tnx-action">
                                                        <span className={`ml-2 badge badge-dot `} >
                                                            <div className="custom-control custom-switch">
                                                                <input type="checkbox" className="custom-control-input form-control" checked={getStudentNotificationData.isEnabled} onClick={() => setModal({ studentNotification: true })} id="customSwitch2" />
                                                                <label className="custom-control-label" htmlFor="customSwitch2"> </label>
                                                            </div> </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        )
                    }
                </Block>
            </Content>
            <Modal isOpen={modal.studentNotification} toggle={() => setModal({ studentNotification: false })} className="modal-dialog-centered" size="md">
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ studentNotification: false }); }} className="close">
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Update Student Notifications</h5>
                        <div className="mt-4">
                            <Form className="row gy-4" onSubmit={handleStudentNotification}>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button type="submit" color="primary" size="md" >
                                                <span>{UpdateNotificationLoading ? <Spinner size="md" /> : "Update"} </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button onClick={(ev) => { ev.preventDefault(); setModal({ studentNotification: false }); }} className="link link-light">
                                                Cancel
                                            </Button>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default Configuration;
