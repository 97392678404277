import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import DatePicker from "react-datepicker";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, FormGroup, Modal, ModalBody, DropdownItem, Spinner, ModalHeader, Form } from "reactstrap";
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Col, Row, RSelect } from "../../../components/Component";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentRoadTestThunk } from "../../../redux/StudentsSlice/GetStudentRoadTestSlice";
import { AddStudentRoadTestThunk, ResetAddStudentRoadTestData } from "../../../redux/StudentsSlice/AddStudentRoadTestLessonSlice";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import moment from "moment";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { getLatLng } from "react-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import { ResetScheduleRoadTestData, ScheduleRoadTestThunk } from "../../../redux/LessonsSlice/ScheduleRoadTestSlice";
import { GetStudentInstructorsThunk } from "../../../redux/StudentsSlice/GetStudentInstructorsSlice";
import DeleteStudentLessonModal from "../StudentModal.js/DeleteStudentLessonModal";
import { ResetUnScheduleLessonData, UnScheduleLessonThunk } from "../../../redux/LessonsSlice/UnScheduleLessonSlice";
import { ChangeLessonStatusThunk, ResetChangeLessonStatusData } from "../../../redux/LessonsSlice/ChangeLessonStatusSlice";
import { ChangeLessonExpiryDateThunk, ResetChangeLessonExpiryData } from "../../../redux/LessonsSlice/ChangeLessonExpiryDateSlice";
import AddRoadTestModal from "./AddRoadTestModal";
import DeleteRoadTestModal from "./DeleteRoadTestModal";

const libraries = ["places"];
const options = {
  // styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: false,
  componentRestrictions: {
    country: "ca",
  },
};

const StudentRoadTest = () => {
  const { studentId } = useParams();
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [roadTestLessonDetail, setRoadTestLessonDetail] = useState();


  const [selectedRoadTestId, setSelectedRoadTestId] = useState('');
  const [selectedLessonId, setSelectedLessonId] = useState("");
  const [selected, SetSelected] = useState(false);
  const [loadingClass, setLoadingClass] = useState(false);
  const [mapButton, setMapButton] = useState(false);
  const [instructorList, setInstructorList] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState("");
  const [expiryDate, setExpiryDate] = useState();

  const [roadTestForm, setRoadTestForm] = useState({
    pickupLocation: "",
    latitude: "",
    longitude: "",
    date: "",
    startTime: "",
    endTime: "",
    city: "",
    roadTestType: "", 
    roadTestTime: "", 
    roadTestCenterAddress: ""
  });
  const [modal, setModal] = useState({
    AddRoadTestLesson: false,
    ScheduleRoadTest: false,
    UnscheduleRoadTest: false,
    showLessonDetail: false,
    showDeleteLesson: false,
    expiryDate: false,
    lessonStatus: false
  });
  
  const { loading, studentRoadTestData } = useSelector((state) => state.Students.getStudentRoadTest);
  const { loading: AddStudentRoadTestLoading, success: AddStudentRoadTestSuccess } = useSelector(
    (state) => state.Students.addStudentRoadTest
  );
  
  console.log('studentRoadTestData', studentRoadTestData)
  const {
    loading: ScheduleRoadTestLoading,
    success: ScheduleRoadTestSuccess,
    error: ScheduleRoadTestError,
    errorMessage: ScheduleRoadTestErrorMessage,
  } = useSelector((state) => state.lessons.scheduleRoadTest);

  const { studentInstructorsData } = useSelector((state) => state.Students.getStudentInstructors);
  const { loading: unScheduleLessonLoading,
    success: unScheduleLessonSuccess,
    error: unScheduleLessonError,
    errorMessage: unScheduleLessonErrorMessage
  } = useSelector((state) => state.lessons.unScheduleLesson);
  const {
    loading: ChangeLessonStatusLoading,
    success: ChangeLessonStatusSuccess,
    error: ChangeLessonStatusError,
    errorMessage: ChangeLessonStatusErrorMessage,
  } = useSelector((state) => state.lessons.changeLessonStatus);
  const {
    loading: ChangeLessonExpiryDateLoading,
    success: ChangeLessonExpiryDateSuccess,
    error: ChangeLessonExpiryDateError,
    errorMessage: ChangeLessonExpiryDateErrorMessage,
  } = useSelector((state) => state.lessons.changeLessonExpiryDate);

  useEffect(() => {
    dispatch(GetStudentRoadTestThunk({ studentId: studentId }));
    dispatch(GetStudentInstructorsThunk({ studentId: studentId }));
  }, [dispatch, studentId]);

  useEffect(() => {
    const list = studentInstructorsData?.instructors?.map((instructor) => {
      return { label: instructor?.fullName, value: instructor?._id };
    });
    setInstructorList(list);
  }, [studentInstructorsData]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 200 * 1000,
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(()=>{
    init();
  },[isLoaded]);

  const handleScheduleRoadTestLesson = (second) => {
    const addOnStartTimeHours = moment(roadTestForm.startTime).toDate().getHours();
    const addOnStartTimeMinutes = moment(roadTestForm.startTime).toDate().getMinutes();
    const addOnEndTimeHours = moment(roadTestForm.endTime).toDate().getHours();
    const addOnEndTimeMinutes = moment(roadTestForm.endTime).toDate().getMinutes();
    const addOnCenterHours = moment(roadTestForm.roadTestTime).toDate().getHours();
    const addOnCenterMinutes = moment(roadTestForm.roadTestTime).toDate().getMinutes();
    dispatch(
      ScheduleRoadTestThunk({
        instructorId: selectedInstructor,
        lessonId: roadTestLessonDetail._id,
        pickupLocation: roadTestForm.pickupLocation,
        latitude: roadTestForm.latitude,
        longitude: roadTestForm.longitude,
        roadTestType: roadTestForm.roadTestType, 
        roadTestTime: moment(roadTestForm.roadTestTime)
        .startOf("year").year(2022)
        .add("hours", addOnCenterHours)
        .add("minutes", addOnCenterMinutes)
        .utcOffset(0, true)
        .toISOString(),
        roadTestCenterAddress: roadTestForm.roadTestCenterAddress,
        date: moment(roadTestForm.date).utcOffset(0, true).toISOString(),
        startTime: moment(roadTestForm.time)
          .startOf("year").year(2022)
          .add("hours", addOnStartTimeHours)
          .add("minutes", addOnStartTimeMinutes)
          .utcOffset(0, true)
          .toISOString(),
        endTime: moment(roadTestForm.endTime)
          .startOf("year").year(2022)
          .add("hours", addOnEndTimeHours)
          .add("minutes", addOnEndTimeMinutes)
          .utcOffset(0, true)
          .toISOString(),
      })
    );

    // dispatch(ScheduleRoadTestThunk());
  };

  const handleChangeLessonStatus = (e) => { 
    e.preventDefault();
    dispatch(ChangeLessonStatusThunk({lessonId: selectedLessonId}))
  }

  const handleChangeLessonExpiryDate = (e) => { 
    e.preventDefault();
    dispatch(ChangeLessonExpiryDateThunk({lessonId: selectedLessonId, expiryDate: moment(expiryDate).utcOffset(0, true).toISOString()}))
  }

  // useEffect(()=>{
  //   setRoadTestForm({...roadTestForm, roadTestTime: studentRoadTestData?.roadTestLesson[0]?.roadTestTime, roadTestCenterAddress: studentRoadTestData?.roadTestLesson[0]?.roadTestCenterAddress, roadTestType: studentRoadTestData?.roadTestLesson[0]?.roadTestType })
  //   // setRoadTestForm(studentRoadTestData?.roadTestLesson[0])
  // },[studentRoadTestData]);

  if (AddStudentRoadTestSuccess) {
    toast.success("Road test lesson has been added successfully.");
    dispatch(ResetAddStudentRoadTestData());
    dispatch(GetStudentRoadTestThunk({ studentId }));
    setModal({ AddRoadTestLesson: false });
  }

  if (ScheduleRoadTestSuccess) {
    toast.success("Road test lesson has been scheduled successfully.");
    dispatch(ResetScheduleRoadTestData());
    dispatch(GetStudentRoadTestThunk({ studentId }));
    setModal({ ScheduleRoadTest: false });
  }

  if (unScheduleLessonSuccess) {
    toast.success("Road test lesson has been unScheduled successfully.");
    dispatch(ResetUnScheduleLessonData());
    dispatch(GetStudentRoadTestThunk({ studentId }));
    setModal({ UnscheduleRoadTest: false });
  }

  if(ChangeLessonStatusSuccess){
    toast.success("Lesson Status has been updated successfully.");
    dispatch(GetStudentRoadTestThunk({ studentId }));
    dispatch(ResetChangeLessonStatusData());
    setModal({ lessonStatus: false });
  }

  if(ChangeLessonExpiryDateSuccess){
    toast.success("Lesson Expiry Date has been updated successfully.");
    dispatch(GetStudentRoadTestThunk({ studentId }));
    dispatch(ResetChangeLessonExpiryData());
    setExpiryDate();
    setModal({ expiryDate: false });
  }

  if (unScheduleLessonError) {
    toast.error(unScheduleLessonErrorMessage);
    dispatch(ResetUnScheduleLessonData());
    dispatch(GetStudentRoadTestThunk({ studentId }));
    setModal({ UnscheduleRoadTest: false });
  }

  if (ScheduleRoadTestError) {
    toast.error(ScheduleRoadTestErrorMessage);
    dispatch(ResetScheduleRoadTestData());
    dispatch(GetStudentRoadTestThunk({ studentId }));
    setModal({ ScheduleRoadTest: false });
  }

  if(ChangeLessonStatusError){
    toast.error(ChangeLessonStatusErrorMessage);
    dispatch(ResetChangeLessonStatusData());
    setModal({lessonStatus: false})
  }

  if(ChangeLessonExpiryDateError){
    toast.error(ChangeLessonExpiryDateErrorMessage);
    dispatch(ResetChangeLessonExpiryData());
    setExpiryDate();
    setModal({expiryDate: false})
  }

  return (
    <React.Fragment>
      <Head title="Student - Road Test Lessons" />
      <Content>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Road Test lessons</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>You have total {studentRoadTestData?.roadTestLesson?.length} lesson.</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModal({ AddRoadTestLesson: true });
                      // handleAddRoadTestLesson(ev)
                    }}
                  >
                    <Icon name="plus" />
                    <span>Add Road Test Lesson</span>
                  </Button>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Card className="card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner p-0">
                    <table className="table table-tranx">
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="tb-tnx-id">
                            <span className="">#</span>
                          </th>
                          <th className="tb-tnx-info">
                            <span className="tb-tnx-desc d-none d-sm-inline-block">
                              <span>Instructor Name</span>
                            </span>
                            <span className="tb-tnx-date d-md-inline-block d-none">
                              <span className="d-none d-md-block">
                                <span>Date</span>
                                <span>Time</span>
                              </span>
                            </span>
                          </th>
                          <th className="tb-tnx-amount is-alt">
                            <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                          </th>
                          <th className="tb-tnx-action">
                            <span>Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentRoadTestData?.roadTestLesson?.length > 0 ? (
                          studentRoadTestData?.roadTestLesson?.map((roadTestDetail, index) => {
                            return (
                              <tr key={roadTestDetail.id} className="tb-tnx-item">
                                <td className="tb-tnx-id">
                                  <a
                                    href="#ref"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <span>{index + 1}</span>
                                  </a>
                                </td>
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="title">{roadTestDetail.instructorName}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">
                                      {roadTestDetail.dateTime
                                        ? moment.utc(roadTestDetail.dateTime).format("MMM Do")
                                        : "-"}
                                    </span>
                                    <span className="date">
                                      {roadTestDetail.dateTime ? moment.utc(roadTestDetail.dateTime).format("LT") : "-"}
                                    </span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-status">
                                    <span
                                      className={`badge badge-dot badge-${roadTestDetail.status === "Completed"
                                        ? "success"
                                        : roadTestDetail.status === "Scheduled"
                                          ? "warning"
                                          : roadTestDetail.status === "Expired" ? "gray" 
                                          : "danger"
                                        }`}
                                    >
                                      {roadTestDetail.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <ul className="link-list-plain">
                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setRoadTestLessonDetail(roadTestDetail);
                                              setModal({ showLessonDetail: true });
                                            }}
                                          >
                                            View Detail
                                          </DropdownItem>
                                        </li>
                                        {(roadTestDetail?.status === "Awaiting Scheduling" || roadTestDetail?.status === "Expired") && (
                                        <>
                                          <li>
                                            <DropdownItem
                                              onClick={(ev) => {
                                                setSelectedLessonId(roadTestDetail._id);
                                                setModal({ lessonStatus: true });
                                              }}
                                            >
                                              Change Status
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              onClick={(ev) => {
                                                setSelectedLessonId(roadTestDetail._id);
                                                setModal({ expiryDate: true });
                                              }}
                                            >
                                              Change Expiry Date
                                            </DropdownItem>
                                          </li>

                                        </>
                                      )}

                                        {roadTestDetail.status !== "Awaiting Scheduling" && roadTestDetail.status !== "Expired" && (
                                          <>
                                            <li>
                                              <DropdownItem
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  setSelectedRoadTestId(roadTestDetail?._id)
                                                  setModal({ UnscheduleRoadTest: true });
                                                }}
                                              >
                                                Unschedule Lesson
                                              </DropdownItem>
                                            </li>
                                          </>

                                        )}
                                        {roadTestDetail.status === "Awaiting Scheduling" && (
                                          <li>
                                            <DropdownItem
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                if(studentRoadTestData?.roadTestLesson[0]?.roadTestTime){

                                                  setRoadTestForm({
                                                    ...roadTestForm, 
                                                    roadTestCenterAddress: studentRoadTestData?.roadTestLesson[0]?.roadTestCenterAddress, 
                                                    roadTestType: studentRoadTestData?.roadTestLesson[0]?.roadTestType,
                                                    roadTestTime: new Date(studentRoadTestData?.roadTestLesson[0]?.roadTestTime)
                                                  })
                                                }
                                                setRoadTestLessonDetail(roadTestDetail);
                                                setModal({ ScheduleRoadTest: true });
                                              }}
                                            >
                                              Schedule
                                            </DropdownItem>
                                          </li>
                                        )}

                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setSelectedLessonId(roadTestDetail._id);
                                              setModal({ showDeleteLesson: true });
                                            }}
                                          >
                                            Delete Lesson
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <span className="text-silent">No data found</span>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </Block>
          </>
        )}

        {/*  ************************* Show Student Road Test Lesson Modal   */}

        <Modal
          isOpen={modal.showLessonDetail}
          toggle={() => setModal({ showLessonDetail: false })}
          className="modal-md"
        >
          <ModalHeader className="fc-event-info" toggle={() => setModal({ showLessonDetail: false })}>
            {roadTestLessonDetail?.studentName}
          </ModalHeader>
          <ModalBody>
            <Row className="gy-3 py-1">
              {roadTestLessonDetail?.instructorName && (
                <Col sm="6">
                  <h6 className="overline-title">Instructor Name</h6>
                  <p id="preview-event-start">{roadTestLessonDetail?.instructorName}</p>
                </Col>
              )}
              {roadTestLessonDetail?.roadTestTime && (
                <Col sm="6">
                  <h6 className="overline-title">Appointment Time</h6>
                  <p id="preview-event-start">{moment.utc(roadTestLessonDetail?.roadTestTime).format("hh:mm A")}</p>
                </Col>
              )}
              {roadTestLessonDetail?.roadTestCenterAddress && (
                <Col sm="6">
                  <h6 className="overline-title">Test Center</h6>
                  <p id="preview-event-start">{roadTestLessonDetail?.roadTestCenterAddress}</p>
                </Col>
              )}
              {roadTestLessonDetail?.roadTestType && (                
                <Col sm="6">
                  <h6 className="overline-title">Test Type</h6>
                  <p id="preview-event-start">{roadTestLessonDetail?.roadTestType}</p>
                </Col>
              )}
  
              {roadTestLessonDetail?.dateTime && (
                <Col sm="6">
                  <h6 className="overline-title">Start Time</h6>
                  <p id="preview-event-start">{moment.utc(roadTestLessonDetail?.dateTime).format("hh:mm A")}</p>
                </Col>
              
              )}
              {roadTestLessonDetail?.dateTime && (
                <Col sm="6" id="preview-event-end-check">
                  <h6 className="overline-title">End Time</h6>
                  <p id="preview-event-end">
                    {moment
                      .utc(roadTestLessonDetail?.dateTime)
                      .add(roadTestLessonDetail?.duration / 60, "hour")
                      .format("hh:mm A")}
                  </p>
                </Col>
              )}
              {roadTestLessonDetail?.duration && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Duration</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.duration}</p>
                </Col>
              )}
              {roadTestLessonDetail?.city && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">City</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.city}</p>
                </Col>
              )}

              {roadTestLessonDetail?.homePickup && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Home Pickup Location</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.homePickup}</p>
                </Col>
              )}
              {roadTestLessonDetail?.status && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Status</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.status}</p>
                </Col>
              )}
              {roadTestLessonDetail?.isRoadTest && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Type</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.isRoadTest ? "Road Test" : "Regular"}</p>
                </Col>
              )}
              {roadTestLessonDetail?.purchaseDate && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Purchase Date</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.purchaseDate.split("T")[0]}</p>
                </Col>
              )}
              {roadTestLessonDetail?.expiryDate && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Expiry Date</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.expiryDate.split("T")[0]}</p>
                </Col>
              )}
              {roadTestLessonDetail?.duration && (
                <Col sm="6" id="preview-event-description-check">
                  <h6 className="overline-title">Duration (In hour)</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.duration / 60}</p>
                </Col>
              )}
              {roadTestLessonDetail?.pickupLocation && (
                <Col sm="12" id="preview-event-description-check">
                  <h6 className="overline-title">Pickup Location</h6>
                  <p id="preview-event-description">{roadTestLessonDetail?.pickupLocation}</p>
                </Col>
              )}
  

            </Row>
          </ModalBody>
        </Modal>

        {/*  ************************* Add Student Road Test Lesson Modal   */}
        {/* <Modal
          isOpen={modal.AddRoadTestLesson}
          toggle={() => setModal({ AddRoadTestLesson: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ AddRoadTestLesson: false })}>Add Road Test</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              <Row className="gx-4 gy-3">
                <Col size="12">
                    <p>
                      Are you sure you want to add a road test lesson?
                    </p>
                </Col>
                <Col size="12">
                  <ul className="d-flex justify-content-between gx-4 mt-1">
                    <li>
                      <Button
                        color="success"
                        className="btn-dim"
                        disabled={AddStudentRoadTestLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(AddStudentRoadTestThunk({ studentId }));
                        }}
                      >
                        {AddStudentRoadTestLoading ? <Spinner /> : "Add Road Test"}
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="btn-dim"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal({ AddRoadTestLesson: false });
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal> */}

        {/*  ************************* Schedule Student Road Test Lesson Modal   */}
        <Modal
          isOpen={modal.ScheduleRoadTest}
          toggle={() => setModal({ ScheduleRoadTest: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ ScheduleRoadTest: false })}>Edit Lesson</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              {false ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                  <Spinner />
                </div>
              ) : (
                <Row className="gx-4 gy-3">
                  <Col size="12">
                    <FormGroup>
                      <label className="form-label" htmlFor="event-title">
                        Select Location
                      </label>
                      <div className="form-control-wrap">
                        {isLoaded && (
                          <Combobox
                            onSelect={async (address) => {
                              setValue(address, false);
                              clearSuggestions();
                              SetSelected(true);
                              setLoadingClass(true);

                              try {
                                const results = await getGeocode({ address });
                                const { lat, lng } = await getLatLng(results[0]);
                                setRoadTestForm({
                                  ...roadTestForm,
                                  latitude: lat,
                                  longitude: lng,
                                  pickupLocation: address,
                                });
                              } catch (error) {
                                console.log(error);
                              } finally {
                                setLoadingClass(false);
                                setMapButton(false);
                              }
                            }}
                          >
                            <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                              <ComboboxInput
                                value={value}
                                style={{
                                  borderColor: "black",
                                  padding: "15px 12px",
                                  width: "100%",
                                  textAlign: "left",
                                  fontFamily: "inherit",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  color: "#071d27",
                                  outline: "none",
                                }}
                                className="map-input form-control"
                                onChange={(e) => {
                                  setValue(e.target.value);
                                }}
                                disabled={!ready}
                                autocomplete={false}
                                placeholder="Enter an address"
                              />
                            </div>
                            <ComboboxPopover>
                              <div className={selected ? "display-none" : "container"}>
                                <div className="row">
                                  <div className="no-padding-mobile">
                                    <ComboboxList
                                      className="map-box map-box-suggestions"
                                      style={{
                                        background: "#ffffff",
                                        border: "1px solid #dfe5e8",
                                        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                        borderRadius: "4px",
                                        padding: "15px",
                                        marginLeft: "15px",
                                        position: "relative",
                                        zIndex: "50000",
                                      }}
                                    >
                                      {status === "OK" &&
                                        data.map(({ place_id, description }) => (
                                          <>
                                            <ComboboxOption
                                              style={{ cursor: "pointer" }}
                                              key={place_id}
                                              value={description}
                                            />
                                            <hr />
                                          </>
                                        ))}
                                    </ComboboxList>
                                  </div>
                                </div>
                              </div>
                            </ComboboxPopover>
                          </Combobox>
                        )}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                  <label className="form-label" htmlFor="testCenter">
                    Test Center
                  </label>
                  <input
                    type="text"
                    id="testCenter"
                    className="form-control"
                    value={roadTestForm?.roadTestCenterAddress}
                    name="testCenter"
                    onChange={(e) => setRoadTestForm({ ...roadTestForm, roadTestCenterAddress: e.target.value })}
                    placeholder="Enter Test Center"
                  />
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <label className="form-label">Appointment Time</label>
                      <Row className="gx-2">
                        <div className="w-100">
                          <div className="form-control-wrap has-timepicker">
                            <DatePicker
                              selected={roadTestForm.roadTestTime}
                              onChange={(time) => setRoadTestForm({ ...roadTestForm, roadTestTime: time })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              placeholderText="Enter appointment Time"
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <label className="form-label">Start Date &amp; Time</label>
                      <Row className="gx-2">
                        <div className="w-55">
                          <div className="form-control-wrap">
                            <DatePicker
                              selected={roadTestForm.date}
                              onChange={(date) => setRoadTestForm({ ...roadTestForm, date: date })}
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                        <div className="w-45">
                          <div className="form-control-wrap has-timepicker">
                            <DatePicker
                              selected={roadTestForm.startTime}
                              onChange={(time) => setRoadTestForm({ ...roadTestForm, startTime: time })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <label className="form-label">End Date &amp; Time</label>
                      <Row className="gx-2">
                        <div className="w-55">
                          <div className="form-control-wrap">
                            <DatePicker
                              selected={roadTestForm.date}
                              onChange={(date) => setRoadTestForm({ ...roadTestForm, date: date })}
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                        <div className="w-45">
                          <div className="form-control-wrap has-timepicker">
                            <DatePicker
                              selected={roadTestForm.endTime}
                              onChange={(time) => setRoadTestForm({ ...roadTestForm, endTime: time })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <label className="form-label" htmlFor="roadTestType">
                      Test Type
                    </label>
                    <input
                      type="text"
                      id="roadTestType"
                      className="form-control"
                      value={roadTestForm?.roadTestType}
                      name="roadTestType"
                      onChange={(e) => setRoadTestForm({ ...roadTestForm, roadTestType: e.target.value })}
                      placeholder="Enter Test Type"
                    />
                  </Col>

                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Choose Instructor</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={instructorList}
                          name="SelectsInstructor"
                          placeholder="Choose Instructor"
                          onChange={(e) => setSelectedInstructor(e.value)}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="d-flex justify-content-between gx-4 mt-1">
                      <li>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={ScheduleRoadTestLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleScheduleRoadTestLesson();
                          }}
                        >
                          {ScheduleRoadTestLoading ? <Spinner /> : "Schedule Road Test"}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-dim"
                          onClick={(e) => {
                            e.preventDefault();
                            setModal({ ScheduleRoadTest: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              )}
            </form>
          </ModalBody>
        </Modal>

        {/*  ************************* Unschedule Student Road Test Lesson Modal   */}
        <Modal
          isOpen={modal.UnscheduleRoadTest}
          toggle={() => setModal({ UnscheduleRoadTest: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ UnscheduleRoadTest: false })}>Unschedule Lesson</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              <Row className="gx-4 gy-3">
                <Col size="12">
                  <div>
                    <span style={{ fontWeight: "bold" }}> Are You sure you want to unschedule a lesson?</span>
                  </div>
                </Col>
                <Col size="12">
                  <ul className="d-flex justify-content-between gx-4 mt-1">
                    <li>
                      <Button
                        color="danger"
                        className="btn-dim"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(UnScheduleLessonThunk({ lessonId: selectedRoadTestId }));
                        }}
                      >
                        {unScheduleLessonLoading ? <Spinner /> : "Unschedule Lesson"}
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="btn-dim"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal({ UnscheduleRoadTest: false });
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        
        {/* ************************* CHANGE LESSON STATUS **************************** */}
        <Modal
          isOpen={modal.lessonStatus}
          toggle={() => setModal({ lessonStatus: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <div onClick={(ev) => { ev.preventDefault(); setModal({ lessonStatus: false });}} className="close cursor_pointer" >
              <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
              <h4 className="title">Change lesson status</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col md="12">
                    <span>Are you sure you want to change lesson status?</span>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={ChangeLessonStatusLoading} onClick={(e) => handleChangeLessonStatus(e)} color="primary" size="md" type="submit">
                          {ChangeLessonStatusLoading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ lessonStatus: false });
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ************************* CHANGE LESSON EXPIRY DATE **************************** */}
        <Modal
          isOpen={modal.expiryDate}
          toggle={() => setModal({ expiryDate: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <div onClick={(ev) => { ev.preventDefault(); setModal({ expiryDate: false });}} className="close cursor_pointer" >
              <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
              <h4 className="title">Change lesson Expiry Date</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col sm="12">
                    <FormGroup>
                      <label className="form-label">Expiry Date</label>
                      <Row className="gx-2">
                        <div className="w-55">
                          <div className="form-control-wrap">
                            <DatePicker
                              selected={expiryDate}
                              onChange={(date) => setExpiryDate(date)}
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={ChangeLessonExpiryDateLoading} onClick={(e) => handleChangeLessonExpiryDate(e)} color="primary" size="md" type="submit">
                          {ChangeLessonExpiryDateLoading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ expiryDate: false });
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </Content>

      {modal.AddRoadTestLesson && <AddRoadTestModal modal={modal} setModal={()=> setModal({ AddRoadTestLesson: false })} />}
      {modal.showDeleteLesson && <DeleteRoadTestModal modal={modal} setModal={()=> setModal({showDeleteLesson: false})} lessonId={selectedLessonId} /> }
      {/* {modal.showDeleteLesson && (
        <DeleteStudentLessonModal
          showDeleteLesson={modal.showDeleteLesson}
          setModal={setModal}
          studentId={studentId}
          lessonId={selectedLessonId}
        />
      )} */}

    </React.Fragment>
  );
};

export default StudentRoadTest;
