import React, { useState } from 'react';
import { Icon } from '../../../components/Component';
import { Button, Card, CardBody, CardText, Col, Row, Spinner } from 'reactstrap';
import 'react-range-slider-input/dist/style.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetAllInCarSheetManeuversThunk, ResetGetAllInCarSheetManeuversData } from '../../../redux/StudentsSlice/InCarSheetSlice/GetAllInCarSheetManeuversSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AddInCarSheet = ({ setPage, selectedTopics, setSelectedTopics, setIsMultiInCarSheet, setIsManeuverSelection }) => {
    const dispatch = useDispatch();
    const {studentId} = useParams();

    const { loading, GetAllInCarSheetManeuversData} = useSelector((state)=> state.Students.InCarSheet.getAllInCarSheetManeuvers)
    const [selectSimulatedRoadTest, setSelectSimulatedRoadTest] = useState();
    const [selectedCards, setSelectedCards] = useState([]);

    const toggleCardSelection = (maneuver) => {
        if(GetAllInCarSheetManeuversData?.simulatedRoadTest._id === maneuver._id){
            if(selectSimulatedRoadTest){
                setSelectSimulatedRoadTest(undefined)
            }else{
                setSelectSimulatedRoadTest(maneuver);
            }
        } else {
            if (selectedCards.includes(maneuver._id)) {
                setSelectedCards(selectedCards.filter(id => id !== maneuver._id));
                setSelectedTopics(selectedTopics.filter(topic => topic._id !== maneuver._id))
            } else {
                setSelectedCards([...selectedCards, maneuver._id]);
                setSelectedTopics([...selectedTopics, maneuver]);
            }
        }
    };

    const handleAddTopics = (e) => { 
        e.preventDefault();
        if(selectSimulatedRoadTest && selectedCards.length > 0 ){
            setIsManeuverSelection({ isBoth: true });
            setIsMultiInCarSheet(true);
            // dispatch(ResetGetAllInCarSheetManeuversData());
            setPage({ add: false, showSelectedTopic: true });
        } 
        if(!selectSimulatedRoadTest) {
            setIsManeuverSelection({ isTopic: true });
            setIsMultiInCarSheet(false);
            setPage({add: false, showSelectedTopic: true });
        }
        if(selectSimulatedRoadTest && selectedCards.length === 0){
            setIsManeuverSelection({ isRoadTest: true });
            setIsMultiInCarSheet(false);
            dispatch(ResetGetAllInCarSheetManeuversData());
            setPage({ add: false, simulatedTestInCarSheet: true });
        }
    };

    useEffect(() => {
        dispatch(GetAllInCarSheetManeuversThunk({ studentId: studentId, isRoadTest: false }));
    },[dispatch, studentId]);

    useEffect(()=>{
        selectedTopics?.forEach((topic) => {
            if (!selectedCards.includes(topic._id)) {
                setSelectedCards(prevSelectedCards => [...prevSelectedCards, topic._id])
            }
        })
    }, []);

    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color="primary" onClick={()=> setPage({add: false, view: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='d-flex justify-content-end'>
                {/* <div>
                    <Icon color="primary" name="calendar-alt"/>
                    <span>Lesson Date: 03/09/2023</span>
                </div> */}
                <p>Outstanding</p>
            </div>
            <h6>Topics</h6>
            {loading ? 
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                    <Spinner />
                </div>
                : 
                <>
                    {GetAllInCarSheetManeuversData?.maneuvers?.map(maneuver => (
                        <Card key={maneuver._id} className={`mt-2 cursor_pointer ${selectedCards.includes(maneuver._id) ? 'selected-card' : ''}`} onClick={() => toggleCardSelection(maneuver)}>
                            <CardBody>
                                <h6>{maneuver?.name}</h6>
                                <CardText>
                                    <div>Previous Grade: {maneuver?.previousGrade ? maneuver?.previousGrade: 0}</div>
                                </CardText>
                            </CardBody>
                        </Card>
                    ))}
                    <Card className={`mt-2 cursor_pointer ${selectSimulatedRoadTest ? 'selected-card' : ''}`} onClick={() => toggleCardSelection(GetAllInCarSheetManeuversData?.simulatedRoadTest)}>
                        <CardBody>
                            <h6>{GetAllInCarSheetManeuversData?.simulatedRoadTest?.name}</h6>
                            <CardText>
                                <div>Previous Grade: {GetAllInCarSheetManeuversData?.simulatedRoadTest?.previousGrade ? GetAllInCarSheetManeuversData?.simulatedRoadTest?.previousGrade: 0}</div>
                            </CardText>
                        </CardBody>
                    </Card>
                </>
            }
            <div className='w-100 d-flex justify-content-center mt-3'>
                <Button className='' onClick={handleAddTopics} >
                    Add Topics
                </Button>
            </div>
        </React.Fragment>
    )
}

export default AddInCarSheet;