import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const EditInstructorProfileThunk = createAsyncThunk(
  "EditInstructorProfile/EditInstructorProfileThunk",
  async (
    { instructorId, fullName, email, phoneNumber, state, country, address, status, serviceAreas, vehicleDetails, gender, bio, languages, instructorImage, baseRate, taxNumber, collectsTax },
    { rejectWithValue }
  ) => {
    try {
      console.log('collectsTax', collectsTax)
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.EditInstructorProfile}?instructorId=${instructorId}`,
        {
          ...(gender && { gender: gender }),
          ...(bio && { bio: bio }),
          ...(instructorImage && { instructorImage: instructorImage }),
          ...(fullName && { fullName: fullName }),
          ...(email && { email: email }),
          ...(phoneNumber && { phoneNumber: phoneNumber }),
          ...(state && { state: state }),
          ...(country && { country: country }),
          ...(address && { address: address }),
          ...(status && { status: status }),
          ...(languages && { languages: languages }),
          ...(serviceAreas && { serviceAreas: serviceAreas }),
          ...(vehicleDetails && { vehicleDetails: vehicleDetails }),
          ...(baseRate && { baseRate: baseRate }),
          ...(taxNumber && { taxNumber: taxNumber }),
          ...(collectsTax && { collectsTax: collectsTax }),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const EditInstructorProfileSlice = createSlice({
  name: "EditInstructorProfile",
  initialState: {
    EditInstructorProfileData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetEditInstructorProfile: (state, action) => {
      state.EditInstructorProfileData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [EditInstructorProfileThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [EditInstructorProfileThunk.fulfilled]: (state, action) => {
      state.EditInstructorProfileData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [EditInstructorProfileThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default EditInstructorProfileSlice.reducer;

export const { ResetEditInstructorProfile } = EditInstructorProfileSlice.actions;

export { EditInstructorProfileThunk };
