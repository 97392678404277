import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UpdateInstructorScheduleThunk = createAsyncThunk(
  "UpdateInstructorSchedule/UpdateInstructorScheduleThunk",
  async (
    { instructorId, oldStartTime, oldEndTime, newStartTime, newEndTime, locations, day, weekly },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.UpdateInstructorSchedule}?instructorId=${instructorId}`,
        {
          oldStartTime,
          oldEndTime,
          newStartTime,
          newEndTime,
          locations,
          day,
          weekly,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const UpdateInstructorScheduleSlice = createSlice({
  name: "UpdateInstructorSchedule",
  initialState: {
    UpdatedInstructorScheduleData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUpdatedInstructorScheduleData: (state, action) => {
      state.UpdatedInstructorScheduleData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [UpdateInstructorScheduleThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UpdateInstructorScheduleThunk.fulfilled]: (state, action) => {
      state.UpdatedInstructorScheduleData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UpdateInstructorScheduleThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default UpdateInstructorScheduleSlice.reducer;

export const { ResetUpdatedInstructorScheduleData } = UpdateInstructorScheduleSlice.actions;

export { UpdateInstructorScheduleThunk };
