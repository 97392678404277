import { combineReducers } from "@reduxjs/toolkit";
import AddInstructorReviewSlice from "./AddInstructorReviewSlice";
import AddInstructorScheduleSlice from "./AddInstructorScheduleSlice";
import AddInstructorSlice from "./AddInstructorSlice";
import AssignInstructorPickupPoint from "./AssignInstructorPickupPoint";
import DeleteInstructorAvailabilitySlice from "./DeleteInstructorAvailabilitySlice";
import DeleteInstructorReviewSlice from "./DeleteInstructorReviewSlice";
import DeleteInstructorSlice from "./DeleteInstructorSlice";
import EditInstructorProfileSlice from "./EditInstructorProfileSlice";
import GetAllInstructorSlice from "./GetAllInstructorSlice";
import GetInstructorDetail from "./GetInstructorDetail";
import GetInstructorLessons from "./GetInstructorLessons";
import GetInstructorListSlice from "./GetInstructorListSlice";
import GetInstructorPickupLocations from "./GetInstructorPickupLocations";
import GetInstructorReviews from "./GetInstructorReviews";
import GetInstructorScheduleSlice from "./GetInstructorScheduleSlice";
import GetInstructorStudents from "./GetInstructorStudents";
import GetInstructorVehicleDetailSlice from "./GetInstructorVehicleDetailSlice";
import UnassignedPickupPointsSlice from "./UnassignedPickupPointsSlice";
import UpdateInstructorScheduleSlice from "./UpdateInstructorScheduleSlice";
import GetInstructorGeoFencingSlice from "./GetInstructorGeoFencingSlice";
import AssignInstructorGeoFencingSlice from "./AssignInstructorGeoFencingSlice";
import UnAssignInstructorGeoFencingSlice from "./UnAssignInstructorGeoFencingSlice";
import AddInstructorEventSlice from "./AddInstructorEventSlice";
import GetAllInstructorInvoiceSlice from "./GetAllInstructorInvoiceSlice";
import GetInstructorInvoiceDetailSlice from "./GetInstructorInvoiceDetailSlice";
import AddInstructorOutOfOfficeSlice from "./AddInstructorOutOfOfficeSlice";

export const instructorsReducers = combineReducers({
  getAllInstructors: GetAllInstructorSlice,
  getInstructorDetail: GetInstructorDetail,
  getInstructorStudent: GetInstructorStudents,
  getInstructorLessons: GetInstructorLessons,
  getInstructorPickupPoints: GetInstructorPickupLocations,
  getInstructorReviews: GetInstructorReviews,
  getInstructorList: GetInstructorListSlice,
  getInstructorVehicleDetails: GetInstructorVehicleDetailSlice,
  assignPickupPoint: AssignInstructorPickupPoint,
  editInstructorProfile: EditInstructorProfileSlice,
  unAssignedPickupPoint: UnassignedPickupPointsSlice,
  addInstructor: AddInstructorSlice,
  getInstructorSchedule: GetInstructorScheduleSlice,
  updateInstructorSchedule: UpdateInstructorScheduleSlice,
  addInstrcutorSchedule: AddInstructorScheduleSlice,
  deleteInstrcutorAvailability: DeleteInstructorAvailabilitySlice,
  addInstructorReview: AddInstructorReviewSlice,
  deleteInstructorReview: DeleteInstructorReviewSlice,
  deleteInstructor: DeleteInstructorSlice,
  getInstructorGeoFencing: GetInstructorGeoFencingSlice,
  assignInstructorGeoFencing: AssignInstructorGeoFencingSlice,
  unAssignInstructorGeoFencing: UnAssignInstructorGeoFencingSlice,
  addInstructorEvent: AddInstructorEventSlice,
  getAllInstructorInvoice: GetAllInstructorInvoiceSlice,
  getInstructorInvoiceDetail: GetInstructorInvoiceDetailSlice,
  addInstructorOutOfOffice: AddInstructorOutOfOfficeSlice
});
