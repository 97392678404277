import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const GetLmsStudentsThunk = createAsyncThunk(
    "LmsStudents/GetLmsStudentsThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ApiEndpoints.LmsStudents.GetLmsStudents, {
                headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            });
            return response.data.data;
        } catch (error) {
            console.log("error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const GetLmsStudentsSlice = createSlice({
    name: "LmsStudents",
    initialState: {
        lmsStudentsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetLmsStudentsData: (state, action) => {
            state.lmsStudentsData = [];
            state.success = false;
            state.error = false;
            state.loading = false;
            state.errorMessage = "";
        },
    },
    extraReducers: {
        [GetLmsStudentsThunk.pending]: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        [GetLmsStudentsThunk.fulfilled]: (state, action) => {
            state.lmsStudentsData = action.payload;
            state.success = true;
            state.loading = false;
        },
        [GetLmsStudentsThunk.rejected]: (state, action) => {
            state.errorMessage = action.payload.message;
            state.error = true;
            state.loading = false;
            state.lmsStudentsData = [];
        },
    },
});

export default GetLmsStudentsSlice.reducer;

export const { ResetLmsStudentsData } = GetLmsStudentsSlice.actions;

export { GetLmsStudentsThunk };
