import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const DeleteInstructorAvailabilityThunk = createAsyncThunk(
  "DeleteInstructorAvailability/DeleteInstructorAvailabilityThunk",
  async ({ instructorId, startTime, endTime, day }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.InstructorsEndPoint.DeleteInstructorAvailability}?instructorId=${instructorId}`,
        {
          startTime,
          endTime,
          day,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const DeleteInstructorAvailabilitySlice = createSlice({
  name: "DeleteInstructorAvailability",
  initialState: {
    DeletedInstructorAvailabilityData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDeletedInstructorAvailabilityData: (state, action) => {
      state.DeletedInstructorAvailabilityData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [DeleteInstructorAvailabilityThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DeleteInstructorAvailabilityThunk.fulfilled]: (state, action) => {
      state.DeletedInstructorAvailabilityData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [DeleteInstructorAvailabilityThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DeleteInstructorAvailabilitySlice.reducer;

export const { ResetDeletedInstructorAvailabilityData } = DeleteInstructorAvailabilitySlice.actions;

export { DeleteInstructorAvailabilityThunk };
