import React, { useEffect, useState } from "react";
import { FormGroup, Modal, ModalBody, Spinner, Col, Button, Row, Form } from "reactstrap";
import { Icon } from "../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import ReactImageUploading from "react-images-uploading";
import { ResetUpdatePickupPointsData, UpdatePickupPointsThunk } from "../../redux/PickupPoints/UpdatePickupPointSlice";
import { GetAllPickupPointsThunk } from "../../redux/PickupPoints/GetAllPickupPoints";

const UpdatePickupLocationModal = ({ modal,setModal, selectedPickupLocationData }) => {
  const dispatch = useDispatch();


  const { loading, success, error, errorMessage } = useSelector((state)=> state.pickupPoints.updatePickupPoint);

  const [selected, SetSelected] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [LogoImageLoading, setLogoImageLoading] = useState(false);
  const [updatePickupPointForm, setUpdatePickupPointForm] = useState({});

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 200 * 1000,
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(()=>{
    init();
  }, []);

  useEffect(()=>{
    setUpdatePickupPointForm({
    name: selectedPickupLocationData?.name,
    city: selectedPickupLocationData?.city,
    pickupAddress: selectedPickupLocationData?.address?.location,
    latitude: selectedPickupLocationData?.address?.latitude?.$numberDecimal,
    longitude: selectedPickupLocationData?.address?.longitude?.$numberDecimal,
    image: selectedPickupLocationData?.images?.image,
    logo: selectedPickupLocationData?.images?.logo,
    _id: selectedPickupLocationData?._id
    })
  },[selectedPickupLocationData]);

  const handleImageUploader = async (e) => {
    e.preventDefault();
    setImageLoader(true);
    const formData = new FormData();
    if (Number((e.target.files[0].size) / 1024) > 1000) {
      toast.error("Image size exceeding 1MB, please upload a smaller image");
      setImageLoader(false);
    } else {
      formData.append("image", e.target.files[0]);
      try {
        const response = await axios.post(`${ApiEndpoints.UploadImages}/pickupLocations`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setUpdatePickupPointForm({ ...updatePickupPointForm, image: response.data.imageName });
        setImageLoader(false);
      } catch (error) {
        setImageLoader(false);
        console.log("error", error);
      }
    }
  };

  const handleLogoImageUploader = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (Number((e.target.files[0].size) / 1024) > 1000) {
      toast.error("Image size exceeding 1MB, please upload a smaller image");
      setLogoImageLoading(false);
    } else {
      setLogoImageLoading(true);
      formData.append("image", e.target.files[0]);
      try {
        const response = await axios.post(`${ApiEndpoints.UploadImages}/pickupLocations`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setUpdatePickupPointForm({ ...updatePickupPointForm, logo: response.data.imageName });
        setLogoImageLoading(false);
      } catch (error) {
        setLogoImageLoading(false);
        console.log("error", error);
      }
    }
  };

  const handleUpdatePickupPoints = (e) => {
    e.preventDefault();
    dispatch(
      UpdatePickupPointsThunk({
        name: updatePickupPointForm?.name,
        city: updatePickupPointForm?.city,
        logo: updatePickupPointForm?.logo,
        image: updatePickupPointForm?.image,
        latitude: updatePickupPointForm?.latitude,
        longitude: updatePickupPointForm?.longitude,
        pickupAddress: updatePickupPointForm?.pickupAddress,
        locationId: updatePickupPointForm?._id
      })
    );
  };

  if(success){
    toast.success("Pickup location has been updated successfully.");
    dispatch(ResetUpdatePickupPointsData());
    dispatch(GetAllPickupPointsThunk());
    setModal();
  }

  if(error){
    toast.error(errorMessage)
    dispatch(ResetUpdatePickupPointsData());
    setModal();
  }

  const { errors } = useForm();

  return (
    <Modal isOpen={modal.update} toggle={() => setModal({ update: false })} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <div
                className="close cursor_pointer"
                onClick={(ev) => {
                  ev.preventDefault();
                  setModal();
                }}
              >
                <Icon name="cross-sm"></Icon>
              </div>
              <div className="p-2">
                <h5 className="title">Update Pickup Location</h5>
                <div className="mt-4">
                  <Form className="row gy-4 mt-4" onSubmit={handleUpdatePickupPoints}>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label">Name</label>
                        <input
                          className="form-control"
                          // ref={register({ required: "This field is required" })}
                          type="text"
                          name="name"
                          defaultValue={updatePickupPointForm.name}
                          onChange={(e) => setUpdatePickupPointForm({ ...updatePickupPointForm, name: e.target.value })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label">City</label>
                        <input
                          className="form-control"
                          // ref={register({ required: "This field is required" })}
                          type="text"
                          name="city"
                          defaultValue={updatePickupPointForm.city}
                          onChange={(e) => setUpdatePickupPointForm({ ...updatePickupPointForm, city: e.target.value })}
                          placeholder="Enter city"
                        />
                        {errors.city && <span className="invalid">{errors.bill.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <FormGroup>
                        <label className="form-label" htmlFor="event-title">
                          Select Location
                        </label>
                        <div className="form-control-wrap">
                          <Combobox
                            onSelect={async (address) => {
                              setValue(address, false);
                              clearSuggestions();
                              SetSelected(true);
                              try {
                                const results = await getGeocode({ address });
                                const { lat, lng } = await getLatLng(results[0]);
                                setUpdatePickupPointForm({
                                  ...updatePickupPointForm,
                                  latitude: lat,
                                  longitude: lng,
                                  pickupAddress: address,
                                });
                              } catch (error) {
                                console.log(error);
                              } finally {
                              }
                            }}
                          >
                            <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                              <ComboboxInput
                                // value={value}
                                style={{
                                  borderColor: "black",
                                  padding: "15px 12px",
                                  width: "100%",
                                  textAlign: "left",
                                  fontFamily: "inherit",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  color: "#071d27",
                                  outline: "none",
                                }}
                                className="map-input form-control"
                                onChange={(e) => {
                                  setValue(e.target.value);
                                }}
                                disabled={!ready}
                                autocomplete={false}
                                placeholder="Enter an address"
                              />
                            </div>
                            <ComboboxPopover>
                              <div className={selected ? "display-none" : "container"}>
                                <div className="row">
                                  <div className="no-padding-mobile">
                                    <ComboboxList
                                      className="map-box map-box-suggestions"
                                      style={{
                                        background: "#ffffff",
                                        border: "1px solid #dfe5e8",
                                        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                        borderRadius: "4px",
                                        padding: "15px",
                                        marginLeft: "15px",
                                        position: "relative",
                                        zIndex: "50000",
                                      }}
                                    >
                                      {status === "OK" &&
                                        data.map(({ place_id, description }) => (
                                          <>
                                            <ComboboxOption
                                              style={{ cursor: "pointer" }}
                                              key={place_id}
                                              value={description}
                                            />
                                            <hr />
                                          </>
                                        ))}
                                    </ComboboxList>
                                  </div>
                                </div>
                              </div>
                            </ComboboxPopover>
                          </Combobox>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Logo Image</label>
                        <input
                          className="form-control"
                          style={{ cursor: "pointer" }}
                          // ref={register({ required: "This field is required" })}
                          type="file"
                          name="logo"
                          onChange={(e) => handleLogoImageUploader(e)}
                        />
                        {LogoImageLoading && <Spinner />}
                        {errors.logo && <span className="invalid">{errors.logo.message}</span>}
                      </FormGroup>
                    </Col>
                    {updatePickupPointForm?.logo &&
                      <Col md="12">
                        <FormGroup>
                          <ReactImageUploading
                            className="form-control"
                            multiple
                            maxNumber={10}
                            dataURLKey="data_url"
                          >
                            {() => (
                              <div className="upload__image-wrapper">
                                <div className="row image-item">
                                  <Row>
                                    <Col className="m-2" md="12">
                                      <img src={`${ApiEndpoints.GetImage}/pickupLocations/${updatePickupPointForm?.logo}`} alt="" width="120" height="100" />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </ReactImageUploading>
                        </FormGroup>
                      </Col>
                    }

                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Location Image</label>
                        <input
                          className="form-control"
                          style={{ cursor: "pointer" }}
                          // ref={register({ required: "This field is required" })}
                          type="file"
                          name="locationImage"
                          onChange={(e) => handleImageUploader(e)}
                        />
                        {imageLoader && <Spinner />}
                        {errors.locationImage && <span className="invalid">{errors.locationImage.message}</span>}
                      </FormGroup>
                    </Col>
                    {updatePickupPointForm?.image &&
                      <Col md="12">
                        <FormGroup>
                          <ReactImageUploading
                            className="form-control"
                            multiple
                            maxNumber={10}
                            dataURLKey="data_url"
                          >
                            {() => (
                              <div className="upload__image-wrapper">
                                <div className="row image-item">
                                  <Row>
                                    <Col className="m-2" md="12">
                                      <img src={`${ApiEndpoints.GetImage}/pickupLocations/${updatePickupPointForm?.image}`} alt="" width="120" height="100" />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </ReactImageUploading>
                        </FormGroup>
                      </Col>
                    }

                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            disabled={loading}
                            color="primary"
                            size="md"
                            type="submit"
                            onClick={(e) => handleUpdatePickupPoints(e)}
                          >
                            {loading ? <Spinner /> : "Add Pickup point"}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal();
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
    </Modal>
  );
};

export default UpdatePickupLocationModal;
