import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ChangeLessonStatusThunk = createAsyncThunk(
"ChangeLessonStatus/ChangeLessonStatusThunk",
async ({ lessonId }, { rejectWithValue }) => {
    try {
        const response = await axios.post(
            `${ApiEndpoints.LesssonsEndPoint.ChangeLessonStatus}?lessonId=${lessonId}`,
            { },
            {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const ChangeLessonStatusSlice = createSlice({
    name: "ChangeLessonStatus",
    initialState: {
        ChangeLessonStatusData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetChangeLessonStatusData: (state, action) => {
        state.ChangeLessonStatusData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [ChangeLessonStatusThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [ChangeLessonStatusThunk.fulfilled]: (state, action) => {
        state.ChangeLessonStatusData = action.payload;
        state.success = true;
        state.loading = false;
        },
        [ChangeLessonStatusThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default ChangeLessonStatusSlice.reducer;

export const { ResetChangeLessonStatusData } = ChangeLessonStatusSlice.actions;

export { ChangeLessonStatusThunk };
