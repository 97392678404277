import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const ScheduleLessonThunk = createAsyncThunk(
  "ScheduleLesson/ScheduleLessonThunk",
  async ({ lessonId, pickupLocation, date, time, latitude, longitude }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.ScheduleLesson}?lessonId=${lessonId}`,
        { pickupLocation, date, time, latitude, longitude },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const ScheduleLessonSlice = createSlice({
  name: "ScheduleLesson",
  initialState: {
    scheduleLessonData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetScheduleLessonData: (state, action) => {
      state.scheduleLessonData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ScheduleLessonThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ScheduleLessonThunk.fulfilled]: (state, action) => {
      state.scheduleLessonData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ScheduleLessonThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ScheduleLessonSlice.reducer;

export const { ResetScheduleLessonData } = ScheduleLessonSlice.actions;

export { ScheduleLessonThunk };
