import { Formik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, RSelect } from '../../components/Component';
import Content from '../../layout/content/Content';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GetInstructorGeoFencingThunk } from '../../redux/InstructorSlice/GetInstructorGeoFencingSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { GetAllGeoFencingThunk } from '../../redux/GeoFencingSlice/GetAllGeoFencingSlice';
import { AssignInstructorGeoFencingThunk, ResetAssignInstructorGeoFencing } from '../../redux/InstructorSlice/AssignInstructorGeoFencingSlice';
import { ResetUnAssignInstructorGeoFencing, UnAssignInstructorGeoFencingThunk } from '../../redux/InstructorSlice/UnAssignInstructorGeoFencingSlice';
import { toast } from 'react-hot-toast';

const InstructorGeoFence = () => {
const dispatch = useDispatch();
    const { instructorId} = useParams();
    const [geoFencingList, setGeoFencingList] = useState([]);
    const [selectedGeoFencing, SetSelectedGeoFencing] = useState();

    const { loading, success, error, errorMessage, instructorGeoFencingData } = useSelector((state)=> state.instructors.getInstructorGeoFencing);
    const { loading: getGeoFencingLoading, geoFencingData } = useSelector((state)=> state.geoFencing.getAllGeoFencing);
    const { 
        loading: assignInstructorGeoFencingLoading, 
        success: assignInstructorGeoFencingSuccess,
        error: assignInstructorGeoFencingError,
        errorMessage: assignInstructorGeoFencingErrorMessage,
        } = useSelector((state)=> state.instructors.assignInstructorGeoFencing);

    const { 
        loading: unAssignInstructorGeoFencingLoading, 
        success: unAssignInstructorGeoFencingSuccess,
        error: unAssignInstructorGeoFencingError,
        errorMessage: unAssignInstructorGeoFencingErrorMessage,
 } = useSelector((state)=> state.instructors.unAssignInstructorGeoFencing);

    const [modal, setModal] = useState({
        add: false,
        delete: false
    })

    const handleAssignInstructorGeoFencing = () => { 
        dispatch(AssignInstructorGeoFencingThunk({instructorId, geoFencingId: selectedGeoFencing}))
    }

    const handleUnAssignInstructorGeoFencing = (e) => {
        e.preventDefault(); 
        dispatch(UnAssignInstructorGeoFencingThunk({instructorId, geoFencingId: selectedGeoFencing}))
    }

    useEffect(()=>{
        dispatch(GetInstructorGeoFencingThunk({instructorId}));
        dispatch(GetAllGeoFencingThunk());
    },[dispatch]);

    useEffect(() => {
        if (geoFencingData?.geoFencing?.length !== 0) {
          const list = geoFencingData?.geoFencing?.map((geoFencing, index) => {
            return {
              label: `(${geoFencing?.name})`,
              value: geoFencing._id,
            };
          });
          setGeoFencingList(list);
        }
    }, [geoFencingData]);
    

    if(assignInstructorGeoFencingSuccess){
        toast.success("Geo Fence has been assigned to an instructor successfully.");
        dispatch(GetInstructorGeoFencingThunk({instructorId}));
        dispatch(ResetAssignInstructorGeoFencing());
        setModal({add: false})
    }

    if(assignInstructorGeoFencingError){
        toast.error(assignInstructorGeoFencingErrorMessage);
        dispatch(GetInstructorGeoFencingThunk({instructorId}));
        dispatch(ResetAssignInstructorGeoFencing());
        setModal({add: false})
    }

    if(unAssignInstructorGeoFencingSuccess){
        toast.success("Geo Fence has been unAssigned to an instructor successfully.");
        dispatch(GetInstructorGeoFencingThunk({instructorId}));
        dispatch(ResetUnAssignInstructorGeoFencing());
        setModal({delete: false})
    }

    if(unAssignInstructorGeoFencingError){
        toast.error(unAssignInstructorGeoFencingErrorMessage);
        dispatch(GetInstructorGeoFencingThunk({instructorId}));
        dispatch(ResetUnAssignInstructorGeoFencing());
        setModal({delete: false})
    }
    


    const { handleSubmit } = useForm();

    return (
        <React.Fragment>
            <Content>

                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle page>Instructor's Geo Fence</BlockTitle>
                            <BlockDes className="text-soft">
                                {/* <p>You have total { } geo fence.</p> */}
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button color="primary" onClick={() => setModal({ add: true })} >
                                <Icon name="plus" />
                                <span>Assign Geo Fence</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                <table className="table table-ulogs">
                    { loading ? 
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
                        <Spinner />
                        </div>
                    :
                    <>
                    <thead className="thead-light">
                        <tr>
                            <th className="tb-col-os ">
                                <span className="overline-title">Name</span>
                            </th>
                            {/* <th className="tb-col-ip">
                                <span className="overline-title">City</span>
                            </th>
                            <th className="tb-col-time">
                                <span className="overline-title">Remove Geo Fence</span>
                            </th> */}
                            <th className="tb-col-action" style={{textAlign: "center"}}>
                                <span className="overline-title">Remove Geo Fence</span>
                            </th>
                        </tr>
                    </thead>
                    {
                        instructorGeoFencingData?.instructorGeoFencings?.map((geoFence, index)=>{
                            return (
                            <tbody>
                            <tr key={1}>
                                <td className="tb-col-os">{geoFence?.name }</td>
                                {/* <td className="tb-col-ip">
                                    <span className="sub-text">{ }</span>
                                </td>
                                <td className="tb-col-time">
                                    <span className="sub-text">
                                        <span className="d-none d-sm-inline-block">{ }</span>
                                    </span>
                                </td> */}
                                <td className="tb-col-action d-flex justify-content-center" >
                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: true }); SetSelectedGeoFencing(geoFence?._id) }} className="link-cross mr-sm-n1 cursor_pointer">
                                        <Icon name="cross" />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        )
                        }
                        )}
                    </>

                    }
                </table>

                {/* ******************* ADD GEO FENCE MODAL ***************** */}

                <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ add: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            {loading ?
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
                            <Spinner />
                            </div>
                        : 
                        <>
                            <h5 className="title">Assign Geo Fence</h5>
                            <div className="mt-4">
                                <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAssignInstructorGeoFencing)}>
                                    <Col md="12">
                                        <FormGroup>
                                            <label className="form-label">Select geo fence</label>
                                            <div className="form-control-wrap">
                                                <RSelect
                                                    options={geoFencingList} onChange={(e) => SetSelectedGeoFencing(e.value)} 
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    {assignInstructorGeoFencingLoading ? <Spinner size="sm" /> : "Assign Geo Fence"}
                                                </Button>
                                            </li>
                                            <li>
                                                <div onClick={(ev) => { ev.preventDefault(); setModal({ add: false }) }} className="link link-light cursor_pointer" >
                                                    Cancel
                                                </div>
                                            </li>
                                        </ul>
                                    </Col>
                                </Form>
                            </div>
                        </>}
                        </div>
                    </ModalBody>
                </Modal>

                {/* ******************* DELETE GEO FENCE MODAL ***************** */}

                <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered" size="md">
                    <ModalBody>
                        <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="close cursor_pointer">
                            <Icon name="cross-sm"></Icon>
                        </div>
                        <div className="p-2">
                            <h4 className="title">Remove Instructor's Geo Fence</h4>
                            <div className="mt-4">
                                <Formik>
                                    <Form className="row gy-4 mt-4 mb-5">
                                        <Col md="12">
                                            <div>
                                                <h6>Are you sure you want to remove?</h6>
                                            </div>
                                        </Col>
                                        <Col size="12">
                                            <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                                                <li>
                                                    <Button onClick={handleUnAssignInstructorGeoFencing} color="danger" size="md" type="submit">
                                                        <span>{unAssignInstructorGeoFencingLoading ? <Spinner size="sm" /> : "Remove Geo Fence"} </span>
                                                    </Button>
                                                </li>
                                                <li>
                                                    <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="link link-light">
                                                        Cancel
                                                    </div>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </Content>

        </React.Fragment>
    )
}

export default InstructorGeoFence;