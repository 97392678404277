

import { Field } from 'formik';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Col, Form, FormGroup, Modal, ModalBody, Spinner } from 'reactstrap';
import { Icon } from '../../../components/Component';
import { AddInstructorReviewsThunk, ResetAddInstructorReviewsData } from '../../../redux/InstructorSlice/AddInstructorReviewSlice';
import { GetInstructorReviewsThunk } from '../../../redux/InstructorSlice/GetInstructorReviews';

const AddInstructorReviewModal = ({ openModal, setOpenModal, instructorId }) => {
    const dispatch = useDispatch();

    const { loading, success, error, errorMessage } = useSelector((state) => state.instructors.addInstructorReview);

    const [formData, setFormData] = useState({
        studentName: "",
        review: "",
        rating: '',
    })

    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddReview = () => {
        dispatch(AddInstructorReviewsThunk({ studentName: formData.studentName, review: formData.review, rating: formData.rating, instructorId: instructorId }))
    }

    if (success) {
        toast.success("Review has been added successfully.");
        dispatch(ResetAddInstructorReviewsData());
        dispatch(GetInstructorReviewsThunk({ instructorId: instructorId }));
        setOpenModal(false);
    }

    if (error) {
        toast.error(errorMessage);
        dispatch(ResetAddInstructorReviewsData());
        dispatch(GetInstructorReviewsThunk({ instructorId: instructorId }));
        setOpenModal(false);
    }

    const { errors, register, handleSubmit } = useForm();
    return (
        <React.Fragment>
            <Modal
                isOpen={openModal.add}
                toggle={() => setOpenModal(false)}
                className="modal-dialog-centered"
                size="lg"
            >
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            setOpenModal(false);
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">Add Review</h5>
                        <div className="mt-4">
                            <Form className="row gy-4 mt-4" onSubmit={handleSubmit(handleAddReview)}>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Student Name</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="studentName"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.studentName}
                                            placeholder="Enter Student Name"
                                        />
                                        {errors.studentName && <span className="invalid">{errors.studentName.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-label">Rating</label>
                                        <input
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="number"
                                            name="rating"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.rating}
                                            placeholder="Enter rating in number"
                                        />
                                        {errors.rating && <span className="invalid">{errors.rating.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup>
                                        <label className="form-label">Review</label>
                                        <textarea
                                            className="form-control"
                                            ref={register({ required: "This field is required" })}
                                            type="text"
                                            name="review"
                                            onChange={(e) => onInputChange(e)}
                                            defaultValue={formData.review}
                                            placeholder="Enter review"
                                        />
                                        {errors.review && <span className="invalid">{errors.review.message}</span>}
                                    </FormGroup>
                                </Col>
                                <Col size="12">
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button color="primary" size="md" type="submit" disabled={loading}>
                                                {loading ? <Spinner /> : "Add Review"}
                                            </Button>
                                        </li>
                                        <li>
                                            <a
                                                href="#cancel"
                                                onClick={(ev) => {
                                                    ev.preventDefault();
                                                    setOpenModal(false);
                                                }}
                                                className="link link-light"
                                            >
                                                Cancel
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Form>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default AddInstructorReviewModal