import React, { useState } from 'react';
import { Icon } from '../../../components/Component';
import { Button, Card, CardBody, CardText, Col, Input, Row } from 'reactstrap';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const ShowSelectedTopic = ({page, setPage, selectedTopics, setSelectedTopics, maneuverNotes, setManeuverNotes, isManeuverSelection }) => {
    const handleSelectedTopics = (second) => { 
        if(isManeuverSelection.isTopic){
            setPage({showSelectedTopic: false, signature: true});
        }
        if(isManeuverSelection.isBoth){
            setPage({showSelectedTopic: false, simulatedTestInCarSheet: true});
        }
    }

    const handleRemoveTopic = (selectedManeuver) => { 
        setSelectedTopics(selectedTopics.filter((topic)=> topic._id !== selectedManeuver._id))
    }

    const handleTopicGrade = (value, selectedManeuver) => { 
        const modifiedTopicsArray = selectedTopics?.map((topic) => {
            if(topic._id === selectedManeuver._id) {
                return { ...topic, previousGrade: value[1]}
            } else {
                return topic;
            }
        });
        setSelectedTopics(modifiedTopicsArray);
    }

    return (
        <React.Fragment>
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button className='btn-dim' color='primary' onClick={()=> setPage({showSelectedTopic: false, add: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className='d-flex justify-content-end'>
                {/* <div>
                    <Icon color="primary" name="calendar-alt"/>
                    <span>Lesson Date: 03/09/2023</span>
                </div> */}
                <div>Outstanding</div>
            </div>
            <h6>Topics</h6>
            <div>
                {selectedTopics?.map(maneuver => (
                    <Card key={maneuver._id} className={`mt-2`}>
                        <CardBody>
                            <div className='d-flex justify-content-between'>
                                <h6>{maneuver?.name}</h6>
                                <Icon name="cross" className="cursor_pointer" onClick={() => handleRemoveTopic(maneuver) } />
                            </div>
                            <CardText>
                            <div>Previous Grade</div>
                            <div className='mt-3'>
                                        <RangeSlider
                                            id={maneuver._id}
                                            className="single-thumb"
                                            defaultValue={[0, maneuver?.previousGrade ? maneuver?.previousGrade: 0]}
                                            min={0}
                                            max={4}
                                            // value={value}
                                            onInput={(value) => handleTopicGrade(value, maneuver)}
                                            thumbsDisabled={[true, false]}
                                            rangeSlideDisabled={false}
                                            step={1}
                                        />
                                        <div className='mx-1 mt-2 d-flex justify-content-between'>
                                            <span>0</span>
                                            <span>1</span>
                                            <span>2</span>
                                            <span>3</span>
                                            <span>4</span>
                                        </div>
                                    </div>
                                </CardText>
                        </CardBody>
                    </Card>
                ))}
                <Row>
                    <Col md="12">
                        <label className="form-label">Notes</label>
                        <Input
                            
                            className='form-control'
                            type='textarea'
                            value={maneuverNotes}
                            onChange={(e)=> setManeuverNotes(e.target.value)}
                            placeholder='Type here'
                        />
                    </Col>
                </Row>
                <div className='w-100 d-flex justify-content-center mt-3'>
                    <Button className='' onClick={handleSelectedTopics}>
                        Save & Proceed
                    </Button>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ShowSelectedTopic;