import React, { useEffect, useState } from 'react'

import { GoogleMap, Polygon, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import { Spinner } from 'reactstrap';

const containerStyle = {
    width: "100%",
    height: "80vh",
};

export default function GeoMaps({ paths= [], point=[], polygons, pickupPoints }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['drawing'],
    });

    const [path, setPath] = useState([]);
    const [map, setMap] = useState();
    const [mapCenter, setMapCenter] = useState(null);
    const [hoveredPolygon, setHoveredPolygon] = useState(null);
    const [hoveredPolygonName, setHoveredPolygonName] = useState(null);
    const [hoveredPolygonInstructors, setHoveredPolygonInstructors] = useState([]);
    const [hoveredPickupPonts, setHoveredPickupPonts] = useState(null);
    const [hoveredPickupPontsName, setHoveredPickupPontsName] = useState(null);
    const [hoveredPickupPontsInstructors, setHoveredPickupPontsInstructors] = useState([]);
    const [infoWindowPosition, setInfoWindowPosition] = useState(null);
    const [PickupPointsPosition, setPickupPointsPosition] = useState(null);

    const handlePolygonMouseOver = (event, name, instructors) => {
        // Access the polygon details or perform other actions here
        setHoveredPolygon(event);
        setHoveredPolygonName(name);
        setHoveredPolygonInstructors(instructors);
        // Calculate the position for the InfoWindow based on the event or other factors
        const infoWindowLat = event.latLng.lat();
        const infoWindowLng = event.latLng.lng();
        setInfoWindowPosition({ lat: infoWindowLat, lng: infoWindowLng });
    };

    const handlePolygonMouseOut = () => {
        // Reset the hovered polygon and InfoWindow position
        setHoveredPolygon(null);
        setInfoWindowPosition(null);
    };


    const handlePickupPontsMouseOver = (pickupPoint) => {
        console.log("coming inside");
        console.log('pickupPoint', pickupPoint);
        // Access the polygon details or perform other actions here
        setHoveredPickupPonts(pickupPoint);
        setHoveredPickupPontsName(pickupPoint?.name);
        setHoveredPickupPontsInstructors(pickupPoint?.instructors);
        // Calculate the position for the InfoWindow based on the event or other factors
        const infoWindowLat = pickupPoint?.lat?.$numberDecimal;
        const infoWindowLng = pickupPoint.lng?.$numberDecimal;
        setPickupPointsPosition({ lat: parseFloat(infoWindowLat), lng: parseFloat(infoWindowLng) });
    };

    const handlePickupPontsMouseOut = () => {
        // Reset the hovered polygon and InfoWindow position
        setHoveredPickupPonts(null);
        setPickupPointsPosition(null);
    };

    const handleLoad = (map) => {
        setMap(map);
        if (!mapCenter) {
            setMapCenter(map.getCenter());
        }
    };

    useEffect(() => {
        if (isLoaded && paths.length !== path.length) {
            setPath(paths);
            }
        }, [isLoaded, paths, path.length]);
    if (loadError) return console.log('Error loading maps');

    return (
        <div className='App'>
            {!isLoaded ?
                <Spinner /> :
                <GoogleMap mapContainerStyle={containerStyle} center={mapCenter ? mapCenter : { lat: 43.64254089782881, lng: -79.38701253368136 }} zoom={12} onLoad={handleLoad} >
                {polygons.map((polygon, index)=>{
                    return (                            
                        <Polygon
                            key={index}
                            paths={polygon.paths}
                            title={polygon.name}
                            onMouseOver={(event) => handlePolygonMouseOver(event, polygon.name, polygon.instructors )}
                            onMouseOut={handlePolygonMouseOut}
                            options={{
                                fillColor: '#37a2d0',
                                fillOpacity: 0.5,
                                strokeColor: 'green',
                                strokeOpacity: 1,
                                strokeWeight: 1,
                            }}
                        />
                    )
                })}
                {pickupPoints.map((pickupPoint) => (
                    <Marker
                        key={pickupPoint.id}
                        position={{ lat: parseFloat(pickupPoint?.lat?.$numberDecimal), lng: parseFloat(pickupPoint.lng.$numberDecimal) }}
                        title={pickupPoint.name}
                        onMouseOver={() => handlePickupPontsMouseOver(pickupPoint)}
                        onMouseOut={handlePickupPontsMouseOut}            
                    />
                )
                )}
                {hoveredPolygon && infoWindowPosition && (
                    <InfoWindow
                        position={infoWindowPosition}
                        onCloseClick={handlePolygonMouseOut}
                    >
                        <div>
                        {/* Display details about the hovered polygon */}
                        <p> <span style={{fontWeight: "bold"}}>Geo Fence Name:</span>  {hoveredPolygonName}</p>
                        {}
                            <p> 
                                <span style={{fontWeight: "bold"}}>Instructors:</span>  
                                {hoveredPolygonInstructors?.map((instructor, index)=>{
                                    return (
                                            <span> {instructor}{hoveredPolygonInstructors?.length !== index + 1 && ", \n"} </span>
                                    )
                                })}
                            </p>
                        </div>
                    </InfoWindow>
                )}
                {hoveredPickupPonts && PickupPointsPosition && (
                    <InfoWindow
                        position={PickupPointsPosition}
                        onCloseClick={handlePickupPontsMouseOut}
                    >
                        <div>
                        {/* Display details about the hovered polygon */}
                        <p> <span style={{fontWeight: "bold"}}>Name: </span>  {hoveredPickupPontsName}</p>
                        {}
                            <p> 
                                <span style={{fontWeight: "bold"}}>Instructors:</span>  
                                {hoveredPickupPontsInstructors?.map((instructor, index)=>{
                                    return (
                                            <span> {instructor}{hoveredPickupPontsInstructors?.length !== index + 1 && ", \n"} </span>
                                    )
                                })}
                            </p>
                        </div>
                    </InfoWindow>
                )}

                </GoogleMap>
            }
        </div>
    )
}
