import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UpdateStudentRefundsThunk = createAsyncThunk(
    "UpdateStudentRefunds/UpdateStudentRefundsThunk",
    async ({ refundId, studentId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(
            `${ApiEndpoints.StudentsEndPoint.UpdateStudentRefunds}?studentId=${studentId}&refundId=${refundId}`,
            { },
            {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
            }
        );
        return response.data;
        } catch (error) {
        console.log("error", error);
        return rejectWithValue(error.response.data);
        }
    }
);

const UpdateStudentRefundssSlice = createSlice({
    name: "UpdateStudentRefundss",
    initialState: {
        UpdateStudentRefundsData: [],
        success: false,
        error: false,
        loading: false,
        errorMessage: "",
    },
    reducers: {
        ResetUpdateStudentRefundsData: (state, action) => {
        state.UpdateStudentRefundsData = [];
        state.success = false;
        state.error = false;
        state.loading = false;
        state.errorMessage = "";
        },
    },
    extraReducers: {
        [UpdateStudentRefundsThunk.pending]: (state, action) => {
        state.loading = true;
        state.success = false;
        state.error = false;
        },
        [UpdateStudentRefundsThunk.fulfilled]: (state, action) => {
        state.UpdateStudentRefundsData = [...state.UpdateStudentRefundsData, action.payload.data];
        state.success = true;
        state.loading = false;
        },
        [UpdateStudentRefundsThunk.rejected]: (state, action) => {
        state.errorMessage = action.payload.message;
        state.error = true;
        state.loading = false;
        },
    },
});

export default UpdateStudentRefundssSlice.reducer;

export const { ResetUpdateStudentRefundsData } = UpdateStudentRefundssSlice.actions;

export { UpdateStudentRefundsThunk };
