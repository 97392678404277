import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const UnScheduleLessonThunk = createAsyncThunk(
  "UnScheduleLesson/UnScheduleLessonThunk",
  async ({ lessonId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${ApiEndpoints.LesssonsEndPoint.UnScheduleLesson}?lessonId=${lessonId}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const UnScheduleLessonSlice = createSlice({
  name: "UnScheduleLesson",
  initialState: {
    unScheduleLessonData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetUnScheduleLessonData: (state, action) => {
      state.unScheduleLessonData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [UnScheduleLessonThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [UnScheduleLessonThunk.fulfilled]: (state, action) => {
      state.unScheduleLessonData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [UnScheduleLessonThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default UnScheduleLessonSlice.reducer;

export const { ResetUnScheduleLessonData } = UnScheduleLessonSlice.actions;

export { UnScheduleLessonThunk };
