import React, { useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card, Spinner } from "reactstrap";
import { Block } from "../../components/Component";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetStudentReviewsThunk } from "../../redux/StudentsSlice/GetStudentReviewsSlice";
import { useParams } from "react-router";

const StudentReviews = () => {
  const { instructorId } = useParams();
  const dispatch = useDispatch();

  const { loading, error, studentReviewsData } = useSelector((state) => state.Students.getStudentReviews);

  useEffect(() => {
    dispatch(GetStudentReviewsThunk({ instructorId: instructorId }));
  }, [dispatch, instructorId]);

  return (
    <React.Fragment>
      <Head title="Student Reviews | Admin Portal"></Head>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <Spinner />
        </div>
      ) : error ? (
        <span>No data Found</span>
      ) : (
        <Content>
          <Block>
            <Card className="card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">Student's Reviews</h5>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Student Name</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="d-none d-md-block">
                              <span>Reviews</span>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentReviewsData?.length > 0
                        ? studentReviewsData?.map((studentDetail, index) => {
                            return (
                              <tr key={studentDetail.id} className="tb-tnx-item">
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span className="">{studentDetail.studentName}</span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="">{studentDetail.review}</span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};

export default StudentReviews;
