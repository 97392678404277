import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// add student
const ChangeInvoiceStatusThunk = createAsyncThunk(
    "AllInvoices/ChangeInvoiceStatusThunk",
    async ({ invoiceId }, { rejectWithValue }) => {
        try {
        const response = await axios.post(`${ApiEndpoints.Invoices.ChangeInvoiceStatus}?invoiceId=${invoiceId}`, 
        {},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        return response.data;
        } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
        }
    }
);

const ChangeInvoiceStatusSlice = createSlice({
  name: "AllInvoices",
  initialState: {
    changeInvoiceStatusData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetChangeInvoiceStatusData: (state, action) => {
      state.changeInvoiceStatusData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [ChangeInvoiceStatusThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [ChangeInvoiceStatusThunk.fulfilled]: (state, action) => {
      state.changeInvoiceStatusData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [ChangeInvoiceStatusThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default ChangeInvoiceStatusSlice.reducer;

export const { ResetChangeInvoiceStatusData } = ChangeInvoiceStatusSlice.actions;

export { ChangeInvoiceStatusThunk };
