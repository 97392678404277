import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorPickupLocationThunk = createAsyncThunk(
  "GetInstructorPickupLocation/GetInstructorPickupLocationThunk",
  async ({ instructorId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.InstructorsEndPoint.GetInstructorPickupLocations}?instructorId=${instructorId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetInstructorPickupLocationSlice = createSlice({
  name: "GetInstructorPickupLocation",
  initialState: {
    instructorPickupLocationData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInstructorPickupLocation: (state, action) => {
      state.instructorPickupLocationData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetInstructorPickupLocationThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetInstructorPickupLocationThunk.fulfilled]: (state, action) => {
      state.instructorPickupLocationData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetInstructorPickupLocationThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.instructorPickupLocationData = [];
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetInstructorPickupLocationSlice.reducer;

export const { ResetInstructorPickupLocation } = GetInstructorPickupLocationSlice.actions;

export { GetInstructorPickupLocationThunk };
