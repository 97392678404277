import React from 'react'
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle, Icon } from '../../../components/Component';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { capitalizeFirstLetter } from '../../../utils/Utils';

const ShowInstructorDaySlots = ({modal, setModal, dayData, handleSpecificTimeSlot}) => {
    return (
        <React.Fragment>
            <BlockHead size="sm">
                <BlockBetween>
                    <BlockHeadContent>
                    <BlockTitle page>{capitalizeFirstLetter(dayData?.day)} Schedule</BlockTitle>
                    </BlockHeadContent>
                </BlockBetween>
            </BlockHead>
            <Row className="d-flex justify-content-between ">
                <Col sm="12">
                    <div className='d-flex justify-content-between'>
                        <div className=' mb-5'>
                            <Button onClick={()=> setModal({showDaySlots: false, showWeeklySlots: true })}> 
                                <Icon name="back-arrow-fill"/>
                                {" "} Back
                            </Button>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                onClick={() => { setModal({ addSlot: true, showDaySlots: false }) }}
                            >
                                <Icon name="plus"> Add Time Slot</Icon>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            {Object.keys(dayData).length !== 0 &&
                dayData?.dayTimeSlot?.map((timeSlot) => {
                    return (
                        <Card className="card-bordered w-100 mb-1">
                            <CardBody className="card-inner">
                            <CardText tag="p" style={{ color: "rgb(92 106 124)", marginBottom: "0.2rem" }}>
                                <div className="d-flex justify-content-between">
                                {moment.utc(timeSlot.startTime).format("hh:mm A")} -{" "}
                                {moment.utc(timeSlot.endTime).format("hh:mm A")}
                                <Link
                                    className="mb-2"
                                    onClick={() =>
                                    handleSpecificTimeSlot({
                                        timeSlot: timeSlot,
                                        location: timeSlot?.locations
                                    })
                                    }
                                >
                                    <Icon style={{ color: "#37a2d0" }} name="edit" size="4x"></Icon>
                                </Link>
                                </div>
                            </CardText>
                            <CardTitle tag="h6"></CardTitle>
                            </CardBody>
                        </Card>
                );
            })}

        </React.Fragment>
    )
}

export default ShowInstructorDaySlots;