import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetInstructorListThunk = createAsyncThunk(
  "GetInstructorList/GetInstructorListThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(ApiEndpoints.InstructorsEndPoint.GetInstructorList, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetInstructorListSlice = createSlice({
  name: "GetInstructorList",
  initialState: {
    instructorsList: {},
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetInstructorListData: (state, action) => {
      state.instructorsList = {};
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetInstructorListThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetInstructorListThunk.fulfilled]: (state, action) => {
      state.instructorsList = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetInstructorListThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetInstructorListSlice.reducer;

export const { ResetInstructorListData } = GetInstructorListSlice.actions;

export { GetInstructorListThunk };
