import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentLessonsThunk = createAsyncThunk(
  "GetStudentLessons/GetStudentLessonsThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.GetStudentLessons}?studentId=${studentId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentLessonsSlice = createSlice({
  name: "GetStudentLessons",
  initialState: {
    studentLessonsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentLessonsData: (state, action) => {
      state.studentLessonsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetStudentLessonsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentLessonsThunk.fulfilled]: (state, action) => {
      state.studentLessonsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentLessonsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.studentLessonsData = [];
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetStudentLessonsSlice.reducer;

export const { ResetStudentLessonsData } = GetStudentLessonsSlice.actions;

export { GetStudentLessonsThunk };
