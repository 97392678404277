import React from 'react'
import { useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { Icon } from '../../components/Component';
import { useDispatch } from 'react-redux';
import { DeleteGeoFencingThunk, ResetDeleteGeoFencingData } from '../../redux/GeoFencingSlice/DeleteGeoFencingSlice';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { GetAllGeoFencingThunk } from '../../redux/GeoFencingSlice/GetAllGeoFencingSlice';

const DeleteGoeFencingModal = ({ modal, setModal, _id }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loading, success, error, errorMessage } = useSelector((state) => state.geoFencing.deleteGeoFencing)
    const handleDeleteGeoFencing = () => {
        dispatch(DeleteGeoFencingThunk({_id}))
    }

    if(success){
        toast.success("Geo Fence has been deleted successfully.");
        dispatch(ResetDeleteGeoFencingData());
        dispatch(GetAllGeoFencingThunk())
        history.push('/geo_fencing');
        setModal(false);
    }

    if(error){
        toast.error(errorMessage);
        dispatch(ResetDeleteGeoFencingData());
        setModal(false);
    }

    return (
        <React.Fragment>

            <Modal isOpen={modal.delete} toggle={() => setModal({ delete: false })} className="modal-dialog-centered" size="md">
                <ModalBody>
                    <div onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="close" style={{ cursor: "pointer" }}>
                        <Icon name="cross-sm"></Icon>
                    </div>
                    <div className="p-2">
                        <h5 className="title">Delete Geo Fence</h5>
                        <div className="mt-4">
                            <Row>
                                <Col xl="12">
                                    <h6>Are you sure you want to delete geo fence?</h6>
                                </Col>
                                <Col xl="12" className='mt-4'>
                                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                        <li>
                                            <Button onClick={handleDeleteGeoFencing} type="submit" color="primary" size="md" >
                                                <span>{loading ? <Spinner size="md" /> : "Delete"} </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button onClick={(ev) => { ev.preventDefault(); setModal({ delete: false }); }} className="link-light">
                                                Cancel
                                            </Button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </ModalBody>
            </Modal>


        </React.Fragment>
    )
}

export default DeleteGoeFencingModal