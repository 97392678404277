import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";
import { useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import ReactImageUploading from "react-images-uploading";
import { getLatLng } from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Form, FormGroup, Modal, ModalBody, Row, Spinner } from "reactstrap";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import { AddPickupPointsThunk, ResetAddPickupPointsData } from "../../redux/PickupPoints/AddPickupPointSlice";
import { GetAllPickupPointsThunk } from "../../redux/PickupPoints/GetAllPickupPoints";
import { RemovePickupPointsThunk, ResetRemovePickupPointsData } from "../../redux/PickupPoints/RemovePickupPointsSlice";
import { ApiEndpoints } from "../../utils/ApiEndpoints";
import UpdatePickupLocationModal from "./UpdatePickupLocationModal.js";

const libraries = ["places"];
const options = {
  // styles: MapStyles,
  disableDefaultUI: true,
  zoomControl: false,
  componentRestrictions: {
    country: "ca",
  },
};

document.title = "Pickup Points | Kruzee Admin";

const PickupPoints = () => {
  const dispatch = useDispatch();

  const { loading, success, error, pickupPointsData } = useSelector((state) => state.pickupPoints.getAllPickupPoints);
  const {
    loading: removePickupPointLoading,
    success: removePickupPointSuccess,
    error: removePickupPointError,
    errorMessage: removePickupPointErrorMessage,
  } = useSelector((state) => state.pickupPoints.removePickupPoint);

  const {
    loading: addPickupPointLoading,
    success: addPickupPointSuccess,
    error: addPickupPointError,
    errorMessage: addPickupPointErrorMessage,
  } = useSelector((state) => state.pickupPoints.addPickupPoint);

  useEffect(() => {
    dispatch(GetAllPickupPointsThunk());
  }, [dispatch]);

  // ***************************************for pickup address*********************************
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [addPickupPointForm, setAddPickupPointForm] = useState({
    name: "",
    city: "",
    pickupAddress: "",
    latitude: "",
    longitude: "",
    image: "",
    logo: "",
  });

  const [locationId, setLocationId] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [LogoImageLoading, setLogoImageLoading] = useState(false);
  const [selectedPickupLocationData, setSelectedPickupLocationData] = useState();
  const [modal, setModal] = useState({
    add: false,
    update: false,
    remove: false
  })

  const { errors } = useForm();

  const [selected, SetSelected] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.653225, lng: () => -79.383186 },
      radius: 200 * 1000,
      componentRestrictions: { country: "ca" },
    },
  });

  useEffect(()=>{
    init();
}, [isLoaded]);

  const handleAddPickupPoints = (e) => {
    e.preventDefault();
    dispatch(
      AddPickupPointsThunk({
        name: addPickupPointForm.name,
        city: addPickupPointForm.city,
        logo: addPickupPointForm.logo,
        image: addPickupPointForm.image,
        latitude: addPickupPointForm.latitude,
        longitude: addPickupPointForm.longitude,
        pickupAddress: addPickupPointForm.pickupAddress,
      })
    );
  };

  const handleRemovePickupPoints = (e) => {
    e.preventDefault();
    dispatch(RemovePickupPointsThunk({ locationId }));
  };

  const handleImageUploader = async (e) => {
    e.preventDefault();
    setImageLoader(true);
    const formData = new FormData();
    if (Number((e.target.files[0].size) / 1024) > 1000) {
      toast.error("Image size exceeding 1MB, please upload a smaller image");
    } else {
      formData.append("image", e.target.files[0]);
      try {
        const response = await axios.post(`${ApiEndpoints.UploadImages}/pickupLocations`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setAddPickupPointForm({ ...addPickupPointForm, image: response.data.imageName });
        setImageLoader(false);
      } catch (error) {
        setImageLoader(false);
        console.log("error", error);
      }
    }
  };

  const handleLogoImageUploader = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (Number((e.target.files[0].size) / 1024) > 1000) {
      toast.error("Image size exceeding 1MB, please upload a smaller image");
    } else {
      setLogoImageLoading(true);
      formData.append("image", e.target.files[0]);
      try {
        const response = await axios.post(`${ApiEndpoints.UploadImages}/pickupLocations`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setAddPickupPointForm({ ...addPickupPointForm, logo: response.data.imageName });
        setLogoImageLoading(false);
      } catch (error) {
        setLogoImageLoading(false);
        console.log("error", error);
      }
    }
  };

  if (removePickupPointSuccess) {
    toast.success("Pickup point has been removed successfully.");
    dispatch(ResetRemovePickupPointsData());
    dispatch(GetAllPickupPointsThunk());
    setRemoveModal(!removeModal);
  }

  if (addPickupPointSuccess) {
    toast.success("Pickup point has been added successfully.");
    dispatch(ResetAddPickupPointsData());
    dispatch(GetAllPickupPointsThunk());
    setOpenDetails(!openDetails);
  }

  if (addPickupPointError) {
    toast.error(addPickupPointErrorMessage);
    dispatch(ResetAddPickupPointsData());
    dispatch(GetAllPickupPointsThunk());
    setOpenDetails(!openDetails);
  }

  if (removePickupPointError) {
    toast.error(removePickupPointErrorMessage);
    dispatch(ResetRemovePickupPointsData());
    dispatch(GetAllPickupPointsThunk());
    setRemoveModal(!removeModal);
  }

  return (
    <React.Fragment>
      {loading || !isLoaded ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ height: "70vh", maxWidth: "100%" }}
        >
          <Spinner />
        </div>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Pickup Points</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {pickupPointsData?.pickupLocations?.length} pickup points.</p>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <ul className="nk-block-tools-opt">
                  <li>
                    <Button color="primary" onClick={() => setOpenDetails(!openDetails)}>
                      <Icon name="plus" />
                      <span>Add Pickup Points</span>
                    </Button>
                  </li>
                </ul>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <table className="table table-ulogs">
            <thead className="thead-light">
              <tr>
                <th className="tb-col-os">
                  <span className="overline-title">Name</span>
                </th>
                <th className="tb-col-ip">
                  <span className="overline-title">City</span>
                </th>
                <th className="tb-col-time">
                  <span className="overline-title">Address</span>
                </th>
                <th className="tb-col-time">
                  <span className="overline-title">&nbsp;</span>
                </th>
                <th className="tb-col-action">
                  <span className="overline-title">&nbsp;</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {pickupPointsData?.pickupLocations?.map((pickupPoint, index) => {
                return (
                  <tr key={index}>
                    <td className="tb-col-os">{pickupPoint.name}</td>
                    <td className="tb-col-ip">
                      <span className="sub-text">{pickupPoint.city}</span>
                    </td>
                    <td className="tb-col-time">
                      <span className="sub-text">
                        {pickupPoint.date}{" "}
                        <span className="d-none d-sm-inline-block">{pickupPoint.address.location}</span>
                      </span>
                    </td>
                    <td className="tb-col-time">
                      <span className="sub-text">
                        <span className="d-none d-sm-inline-block"> 
                          <span className="cursor_pointer" style={{color: "#37a2d0"}} onClick={()=> {setModal({update: true}); setSelectedPickupLocationData(pickupPoint)}} >edit</span> 
                        </span>
                      </span>
                    </td>
                    <td className="tb-col-action">
                      <a
                        href="#delete"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setRemoveModal(!removeModal);
                          setLocationId(pickupPoint._id);
                        }}
                        className="link-cross mr-sm-n1"
                      >
                        <Icon name="cross" />
                      </a>
                      {/* )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Modal isOpen={openDetails} toggle={(e) => { e.preventDefault(); setOpenDetails(!openDetails); }} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setOpenDetails(!openDetails);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="p-2">
                <h5 className="title">Add Pickup Location</h5>
                <div className="mt-4">
                  <Form className="row gy-4 mt-4" onSubmit={handleAddPickupPoints}>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label">Name</label>
                        <input
                          className="form-control"
                          // ref={register({ required: "This field is required" })}
                          type="text"
                          name="name"
                          // defaultValue={addPickupPointForm.name}
                          onChange={(e) => setAddPickupPointForm({ ...addPickupPointForm, name: e.target.value })}
                        />
                        {errors.name && <span className="invalid">{errors.name.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label">City</label>
                        <input
                          className="form-control"
                          // ref={register({ required: "This field is required" })}
                          type="text"
                          name="city"
                          defaultValue={addPickupPointForm.city}
                          onChange={(e) => setAddPickupPointForm({ ...addPickupPointForm, city: e.target.value })}
                          placeholder="Enter city"
                        />
                        {errors.city && <span className="invalid">{errors.bill.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <FormGroup>
                        <label className="form-label" htmlFor="event-title">
                          Select Location
                        </label>
                        <div className="form-control-wrap">
                          <Combobox
                            onSelect={async (address) => {
                              setValue(address, false);
                              clearSuggestions();
                              SetSelected(true);
                              try {
                                const results = await getGeocode({ address });
                                const { lat, lng } = await getLatLng(results[0]);
                                setAddPickupPointForm({
                                  ...addPickupPointForm,
                                  latitude: lat,
                                  longitude: lng,
                                  pickupAddress: address,
                                });
                              } catch (error) {
                                console.log(error);
                              } finally {
                              }
                            }}
                          >
                            <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                              <ComboboxInput
                                // value={value}
                                style={{
                                  borderColor: "black",
                                  padding: "15px 12px",
                                  width: "100%",
                                  textAlign: "left",
                                  fontFamily: "inherit",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "18px",
                                  color: "#071d27",
                                  outline: "none",
                                }}
                                className="map-input form-control"
                                onChange={(e) => {
                                  setValue(e.target.value);
                                }}
                                disabled={!ready}
                                autocomplete={false}
                                placeholder="Enter an address"
                              />
                              {/* <img
                          onClick={clearInput}
                          className="map-input-exit"
                          src={process.env.PUBLIC_URL + "/images/exit.svg"}
                          alt="exit"
                        /> */}
                            </div>
                            <ComboboxPopover>
                              <div className={selected ? "display-none" : "container"}>
                                <div className="row">
                                  <div className="no-padding-mobile">
                                    <ComboboxList
                                      className="map-box map-box-suggestions"
                                      style={{
                                        background: "#ffffff",
                                        border: "1px solid #dfe5e8",
                                        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                        borderRadius: "4px",
                                        padding: "15px",
                                        marginLeft: "15px",
                                        position: "relative",
                                        zIndex: "50000",
                                      }}
                                    >
                                      {status === "OK" &&
                                        data.map(({ place_id, description }) => (
                                          <>
                                            <ComboboxOption
                                              style={{ cursor: "pointer" }}
                                              key={place_id}
                                              value={description}
                                            />
                                            <hr />
                                          </>
                                        ))}
                                    </ComboboxList>
                                  </div>
                                </div>
                              </div>
                            </ComboboxPopover>
                          </Combobox>
                        </div>
                      </FormGroup>
                    </Col>


                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Logo Image</label>
                        <input
                          className="form-control"
                          style={{ cursor: "pointer" }}
                          // ref={register({ required: "This field is required" })}
                          type="file"
                          name="logo"
                          onChange={(e) => handleLogoImageUploader(e)}
                        />
                        {LogoImageLoading && <Spinner />}
                        {errors.logo && <span className="invalid">{errors.logo.message}</span>}
                      </FormGroup>
                    </Col>
                    {addPickupPointForm?.logo &&
                      <Col md="12">
                        <FormGroup>
                          <ReactImageUploading
                            className="form-control"
                            multiple
                            maxNumber={10}
                            dataURLKey="data_url"
                          >
                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                              <div className="upload__image-wrapper">

                                <div className="row image-item">
                                  <Row>
                                    <Col className="m-2" md="12">
                                      <img src={`${ApiEndpoints.GetImage}/pickupLocations/${addPickupPointForm?.logo}`} alt="" width="120" height="100" />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </ReactImageUploading>

                        </FormGroup>
                      </Col>
                    }

                    <Col md="12">
                      <FormGroup>
                        <label className="form-label">Location Image</label>
                        <input
                          className="form-control"
                          style={{ cursor: "pointer" }}
                          // ref={register({ required: "This field is required" })}
                          type="file"
                          name="locationImage"
                          onChange={(e) => handleImageUploader(e)}
                        />
                        {imageLoader && <Spinner />}
                        {errors.locationImage && <span className="invalid">{errors.locationImage.message}</span>}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            disabled={addPickupPointLoading}
                            color="primary"
                            size="md"
                            type="submit"
                            onClick={(e) => handleAddPickupPoints(e)}
                          >
                            {addPickupPointLoading ? <Spinner /> : "Add Pickup point"}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setOpenDetails(!openDetails);
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal isOpen={removeModal} toggle={() => setRemoveModal(!removeModal)} className="modal-dialog-centered" size="md">
            <ModalBody>
              <div
                onClick={(ev) => {
                  ev.preventDefault();
                  setRemoveModal(!removeModal);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </div>
              <div className="p-2">
                <h5 className="title">Delete Pickup Points</h5>
                <div className="mt-4">
                  <Form className="row gy-4 mt-4" >
                    <Col md="12">
                      <div>
                        <span>Are you sure you want to delete pickup location?</span>
                      </div>
                    </Col>
                    <Col size="12">
                      <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button onClick={handleRemovePickupPoints} color="danger" size="md" type="submit">
                            Delete
                            <span>{removePickupPointLoading && <Spinner size="sm" />} </span>
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setRemoveModal(!removeModal);
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Form>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <UpdatePickupLocationModal modal={modal} setModal={()=> setModal({update: false})} selectedPickupLocationData={selectedPickupLocationData} />
        </Content>
      )}
    </React.Fragment>
  );
};

export default PickupPoints;
