import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

const GetStudentReviewsThunk = createAsyncThunk(
  "GetStudentReviews/GetStudentReviewsThunk",
  async ({ studentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${ApiEndpoints.StudentsEndPoint.GetStudentReviews}?studentId=${studentId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const GetStudentReviewsSlice = createSlice({
  name: "GetStudentReviews",
  initialState: {
    studentReviewsData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetStudentReviewsData: (state, action) => {
      state.studentReviewsData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [GetStudentReviewsThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [GetStudentReviewsThunk.fulfilled]: (state, action) => {
      state.studentReviewsData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [GetStudentReviewsThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload.message;
      state.error = true;
      state.loading = false;
    },
  },
});

export default GetStudentReviewsSlice.reducer;

export const { ResetStudentReviewsData } = GetStudentReviewsSlice.actions;

export { GetStudentReviewsThunk };
